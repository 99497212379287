import axios from "axios";

const state = {
    order_list: [],
    order_list_h: [],
    order: [],
}
const actions = {
    async order_list_GET ({commit}, {number, page}) { //'/grid?limit=25&page=1&order[]=created_at:desc'
        let url = this.state.apiDomain + this.state.apiUrls.order + '/grid?limit=' + number +
                 '&page=' + page + '&order[]=created_at:desc'
        await axios.get( url )
            .then((response) => {
                commit('upd_order_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async order_list_GET_h ({commit}) { //'/grid?limit=25&page=1&order[]=created_at:desc'
        let url = this.state.apiDomain + this.state.apiUrls.order + '/grid?order[]=created_at:desc'
        await axios.get( url )
            .then((response) => {
                commit('upd_order_list_h', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async order_GET({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.order +'/' + id;
        return await axios.get( url )
            .then((response) => {
                commit('upd_order', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    order_DELETE({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.order +'/'+ id
        return axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async order_status({commit}, order_id){
        let url = this.state.apiDomain + '/api/admin/order/status'
        return axios.put( url, { 'id': order_id, 'status': 'working'})
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async update_order({commit}, data){
        let url = this.state.apiDomain + '/api/admin/order'
        return axios.put( url, data).then((res) =>{
            console.log(res)
        })
            .catch((err) => {console.log(err)})
    }
}
const mutations = {
    upd_order_list: (state, item) => { state.order_list = item},
    upd_order_list_h: (state, item) => { state.order_list_h = item},
    upd_order: (state, item) => { state.order = item},
}
const getters = {
    order_list(state) { return state.order_list },
    order_list_h(state) { return state.order_list_h },
    order(state) { return state.order },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
