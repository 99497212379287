<template>
  <v-card v-if="article.data"
          light tile>
    <v-toolbar class="mb-4">
      <v-btn depressed large color="primary" to="/blog">
        Все статьи
      </v-btn>
      <v-spacer/>
      <v-btn-toggle v-model="article.language.id" mandatory @change="lang_art">
        <v-btn v-for="lang in article.languages" :key="lang.id"
               :value="lang.id"
               active-class="primary white--text"
               class="text-uppercase">
          <v-img :src="'https://api.timetr.org' + lang.flag"
                 width="20" height="20" class="rounded-circle mr-2"/>
          {{lang.name}}
        </v-btn>
      </v-btn-toggle>
      <v-btn depressed large color="primary" class="ml-5" @click="save_art">
        Сохранить
      </v-btn>
    </v-toolbar>
    <v-card class="pa-1" tile flat>
      <div class="d-flex flex-column flex-md-row justify-space-between">
        <v-card tile flat
                max-width="790"
                class="ml-5 hidden-md-and-up">
          <img_load :img="article.data.image" width="100%" height="560" :set_img="set_img" contain max_height/>
        </v-card>
        <v-card tile flat
                class="ml-5 hidden-sm-and-down">
          <v-slider v-model="article.data.keywords" validate-on-blur
                    :min="0" :max="100"
                    label="Позиция"
                    class="align-center mt-10" >
            <template v-slot:append>
              <v-text-field v-model="article.data.keywords"
                            class="mt-0 pt-0"
                            type="number"
                            style="width: 50px"/>
              <span class="mt-2">%</span>
            </template>
          </v-slider>
          <img_load :img="article.data.image" width="255" height="560" :set_img="set_img" :left="article.data.keywords"/>
          <div class="d-flex flex-column align-center pr-10">
            <span class="grey--text">
              255
              <v-icon x-small>mdi-close</v-icon>
              560
            </span>
            <div style="width: 260px" class="grey--text">Рекомендуем загружать изображения размером 790<v-icon x-small>mdi-close</v-icon>560, поскольку для экранов, шириной менее 800px, изображение растягивается по ширине </div>
          </div>
          <v-card flat class="mb-12 hidden-sm-and-down hidden-xl-only" width="300">
            <v-date-picker v-model="article.data.subtext"
                           :max="this.date"
                           :show-current="false"
                           elevation="1"
                           class="mt-3"
                           @mouseenter:month="mouseEnter"
                           @mouseleave:month="mouseLeave"/>
          </v-card>
        </v-card>
        <v-card flat class="mx-5 mb-12 pr-5 pr-md-0" width="100%">
          <v-text-field v-model="article.data.title" label="Название услуги" outlined class="mb-n3 mt-3"/>
          <v-autocomplete v-model="article.article_category"
                          :items="article.article_categories"
                          item-text="title"
                          outlined return-object
                          label="Тег статьи" />
          <VueEditor v-model="article.data.text" :editor-toolbar="customToolbar"/>
        </v-card>
        <v-card flat class="mx-5 mb-12 hidden-md-only hidden-lg-only" width="300">
          <v-date-picker v-model="article.data.subtext"
                         :max="this.date"
                         :show-current="false"
                         elevation="1"
                         class="mt-3"
                         @mouseenter:month="mouseEnter"
                         @mouseleave:month="mouseLeave"/>
        </v-card>
      </div>
    </v-card>
    <v-bottom-sheet v-model="snackbar">
      <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
        <v-icon v-if="text === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
        <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
        <span v-if="text === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
        <span v-else>{{text}}</span>
        <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Img_load from "@/components/img_load";
export default {
name: "bl_id",
  components: {VueEditor, Img_load},
  data: () => ({
    text: '',
    timeout: 1500,
    snackbar: false,
    qq: 'https://api.timetr.org/uploads/wyYXuAMxWXOxPR4t0olP92Sdxpl9JAXeN1fySn4K.png',
    date: new Date().toISOString().substr(0, 10),
    done: [false, false, false],
    mouseMonth: null,
    customToolbar: [
      [{header: ['false', '3']}],
      ["bold", "italic", "underline"],
      [{  align: ""}, { align: "center"}, { align: "right" }, {align: "justify"}],
      [{color: []}, {background: []}],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ['clean']
    ]
  }),
  computed: {
    article() { return this.$store.getters["article/article"] },
    art_put() { return this.$store.getters["article/art_put"] },
  },
  watch: {
    snackbar () {
      setTimeout(()=> {this.snackbar = false}, this.timeout)
    }
  },
  methods:{

    change({coordinates, canvas}) {
      console.log(coordinates, canvas)
    },

    mouseEnter (month) {
      this.$set(this.done, 1, true)
      this.mouseMonth = month
    },
    mouseLeave () {
      this.mouseMonth = null
    },

    async cr(){
      await this.$store.dispatch('article/GET_article',  {id: this.$route.params.id, lang: 1})
    },
    async start(){
      if(this.article.language) {
        await this.$store.dispatch('article/GET_article', {id: this.$route.params.id, lang: this.article.language.id})
      }
    },
    lang_art(){
      this.$store.dispatch('article/GET_article',  {id: this.article.data.id, lang: this.article.language.id})
    },
    async save_art(){
      console.log(this.article)
      this.article.data.language_id = this.article.language.id
      this.article.data.article_category_id = this.article.article_category.id
      this.article.data.article_author_id = 1
      this.article.data.alt = this.article.data.title
      this.article.data.keywords = String(this.article.data.keywords)
      this.article.data.seo_title = this.article.data.title
      if (!this.article.data.subtext) {
        this.article.data.subtext = this.article.data.title
      }
      else {
        this.article.data.subtext = this.article.data.subtext
      }
      this.article.data.url = this.article.data.title
      this.article.data.description = this.article.data.title
      if (!this.article.data.text) {
        this.article.data.text = '<p></p>'
      }
      await this.$store.dispatch('article/PUT_article', {
        id: this.article.data.id,
        data: this.article.data
      }).then(res => {
        if(this.art_put.data) {
          this.text = this.art_put.data
        }
        else this.text = this.art_put
        this.snackbar = true
        this.start()
      })
    },
    set_img(item, img){
      this.article.data.image = img
      this.article.data.language_id = this.article.language.id
      this.article.data.article_category_id = this.article.article_category.id
      this.article.data.article_author_id = 1
      if (!this.article.data.text) {
        this.article.data.text = '<p></p>'
      }
      this.$store.dispatch('article/PUT_article', {
        id: this.article.data.id,
        data: this.article.data
      })
    },
  },
  created() {
  this.cr()
  },
  mounted() {
  this.start()
  }
}
</script>

<style scoped lang="scss">

::v-deep .v-label{
  min-width: 62px !important;
}

::v-deep .v-input__slider{
  min-width: 300px !important;
  width: 300px !important;
}
</style>
