import axios from "axios";

const state = {
    interfaceGroup: [],
    interface: [],
    text: [],
    int_res: [],
}

const actions = {

    async PUT_interface( {commit}, {id, data}){
        let url = 'http://api.timetr.org/api/interface/' + id
        return await axios.put( url, data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async GET_interfaceGroup ({commit}, {id, num, page}){
        let url = this.state.apiDomain + this.state.apiUrls.interfaceGroup + '?number=' + num + '&language_id=1&page_id=' + id + '&page=' + page;
        return await axios.get( url )
            .then((response) => {
                commit('update_interfaceGroup', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async POST_interfaceGroup ({commit}, group){
        let url = this.state.apiDomain + this.state.apiUrls.interfaceGroup
        return await axios.post( url, group )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    delete_interfaceGroup({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.interfaceGroup +'/'+ id
        return axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    delete_interface_field({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.interface +'/'+ id
        return axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    // -----------interface-----------
    async GET_interface ({commit}, {id, lang}){
        let url = this.state.apiDomain + this.state.apiUrls.interface + '?interface_group_id=' + id + '&language_id=' + lang
        return await axios.get( url )
            .then((response) => {
                commit('update_interface', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async POST_interface ({commit}, field){
        let url = this.state.apiDomain + this.state.apiUrls.interface
        return await axios.post( url, field )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async update_interfase_fields ({commit}, fields){
        let url = this.state.apiDomain + this.state.apiUrls.interface + '/update'
        return await axios.put( url , fields )
            .then((response) => {
                commit('upd_int_res', response)
                console.log(response)
            })
            .catch((error) => {
                commit('upd_int_res', error)
                console.log(error)
            })
    },
    // -----------text-----------
    async GET_text ({commit}, {id, lang} ){
        let url = this.state.apiDomain + this.state.apiUrls.text + '?interface_group_id=' + id + '&language_id=' + lang
        return await axios.get( url )
            .then((response) => {
                commit('update_text', response.data)
            })
            .catch((error) => { console.log(error) })
    },
    POST_text: async function ({commit}, field) {
        let url = this.state.apiDomain + this.state.apiUrls
        return await axios.post(url, field)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    async update_text_fields ({commit}, fields){
        let url = this.state.apiDomain + this.state.apiUrls.text + '/update'
        return await axios.put( url , fields )
            .then((response) => {
                commit('upd_int_res', response)
                console.log(response)
            })
            .catch((error) => {
                commit('upd_int_res', error)
                console.log(error)
            })
    },
}

const mutations = {
    update_interfaceGroup: (state, item) => { state.interfaceGroup = item },
    update_interface: (state, item) => { state.interface = item },
    update_text: (state, item) => { state.text = item },
    upd_int_res: (state, item) => { state.int_res = item },
}

const getters = {
    interfaceGroup(state) {return state.interfaceGroup},
    interface(state) {return state.interface},
    text(state) {return state.text},
    int_res(state) {return state.int_res},
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
