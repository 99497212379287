<template>
  <div>
    <v-toolbar>
      Блог
    </v-toolbar>
    <v-row class="px-5 py-5"  v-if="article_cat_list.data">
      <v-col cols="12" lg="5">
        <v-card>
          <v-toolbar color="primary" dark>
            Теги
            <v-spacer/>
            <v-btn @click="overlay_add = true" light x-large class="mr-2">
              Добавить
            </v-btn>
          </v-toolbar>
          <div class="pa-5">
            <page_num v-if="article_cat_list.meta" :on_change="change_num">
              <v-pagination slot="pgn" v-if="article_cat_list.meta.last_page !== 1"
                            @input="on_change(article_cat_list.meta.current_page)"
                            v-model="article_cat_list.meta.current_page"
                            :length="article_cat_list.meta.last_page"
                            total-visible="5" circle/>
            </page_num>
            <v-divider class="mb-3 mt-2"/>
            <v-row>
              <v-card width="calc(100% - 200px)" flat class="primary--text pl-5">
                Название тега:
              </v-card>
              <v-card width="160" flat class="primary--text">
                Статьи:
              </v-card>
            </v-row>

            <v-list>
              <div v-for="(item, i) in article_cat_list.data" :key="i">
                <v-list-item link>
                  <v-card flat
                          width="calc(100% - 150px)"
                          @click="open_blg(item)">
                    {{item.title}}
                  </v-card>
                  <v-spacer/>
                  <div class="d-flex pr-4">
                    <v-img v-for="im in  item.languages"
                           :key="im.id" height="22" width="22"  class="rounded-circle ml-1"
                           :src="'https://api.timetr.org/' + im.flag"/>
                  </div>
                  <v-card width="50" class="text-center" flat>
                    {{item.articles_number}}
                  </v-card>
                  <v-switch @click="act(item.id, item.is_active)"
                            v-model="item.is_active" hide-details class="mt-n1 ml-6"></v-switch>
                  <v-btn :disabled="item.articles_number > 0" @click="del(item.id)" fab text>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item>
                <v-divider/>
              </div>
            </v-list>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="7">
        <v-card>
          <v-toolbar color="primary" dark>
            Статьи
            <v-spacer/>
            <v-btn @click="overlay_add_art = true" light x-large class="mr-2">
              Добавить
            </v-btn>
          </v-toolbar>
          <div class="pa-5">
            <page_num v-if="article_list.meta" :on_change="change_numa">
              <v-pagination slot="pgn" v-if="article_list.meta.last_page !== 1"
                            @input="on_change_a(article_list.meta.current_page)"
                            v-model="article_list.meta.current_page"
                            :length="article_list.meta.last_page"
                            total-visible="5" circle/>
              <v-select v-model="art_cat"
                        @change="select_cat"
                        @click:clear="select_cat"
                        label="Все теги"
                        :items="ct"
                        filled dense clearable/>
            </page_num>
            <v-row class="mt-n3">
              <v-card width="calc(58% - 100px)" flat class="primary--text pl-5">
                Название статьи:
              </v-card>
              <v-card width="calc(42% - 100px)" flat class="primary--text">
                Тег:
              </v-card>
            </v-row>
            <v-list>
              <div v-for="(item, i) in article_list.data" :key="i">
                <v-list-item link>
                  <v-card flat
                          width="calc(60% - 100px)"
                          @click="open_art(item)">
                    {{item.name}}
                  </v-card>
                  <v-card flat
                          width="calc(40% - 100px)"
                          @click="open_art(item)">
                    #{{item.article_category.title}}
                  </v-card>
                  <v-spacer/>
                  <div class="d-flex pr-4">
                    <v-img v-for="im in  item.languages"
                           :key="im.id" height="22" width="22"  class="rounded-circle ml-1"
                           :src="'https://api.timetr.org/' + im.flag"/>
                  </div>
                  <v-switch @click="act_art(item.id, item.is_active)"
                            v-model="item.is_active" hide-details class="mt-n1"></v-switch>
                  <v-btn @click="ask_del(item.id, item.name)" fab text>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item>
                <v-divider/>
              </div>
            </v-list>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay_add" z-index="10">
      <v-card class="pa-10" light width="550">
        <v-btn absolute right text fab
               class="mt-n10"
               @click="clean">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Введите имя тега
        </v-card-title>
        <v-text-field v-model="blog_title"
                      outlined/>
        <v-btn @click="add_new"
               block
               :disabled="!blog_title">
          OK
        </v-btn>
      </v-card>
    </v-overlay>
    <v-overlay :value="overlay_add_art" z-index="10">
      <v-card class="pa-10" light width="550">
        <v-btn absolute right text fab
               class="mt-n10"
               @click="clean">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Введите данные
        </v-card-title>
        <v-text-field v-model="art_title"
                      label="Название статьи"
                      outlined/>
        <v-select v-model="art_cat"
                  label="Тег"
                  :items="ct"
                  outlined/>
        <v-btn @click="add_new_art"
               block
               :disabled="!art_title ? false : !art_cat">
          OK
        </v-btn>
      </v-card>
    </v-overlay>
    <v-overlay :value="overlay_blog" z-index="10">
      <v-card v-if="article_cat.data"
              light width="700">
        <v-btn absolute right text fab
               class="mt-1"
               @click="clean">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar>
          <v-btn-toggle v-model="article_cat.language.id" mandatory @change="lang">
            <v-btn v-for="lang in article_cat.languages" :key="lang.id"
                   :value="lang.id"
                   active-class="primary white--text"
                   class="text-uppercase">
              <v-img :src="'https://api.timetr.org' + lang.flag"
                     width="20" height="20" class="rounded-circle mr-2"/>
              {{lang.name}}
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
        <v-text-field v-model="article_cat.data.title" label="Имя тега" outlined class="mb-n3 mt-5 mx-5"/>
        <v-btn @click="save_cat" class="ml-5 mb-5" color="primary">
          Сохранить
        </v-btn>
      </v-card>
    </v-overlay>
    <v-overlay :value="overlay_art" z-index="10">
      <v-card v-if="article.data"
              light max-width="1200" min-width="50vw">
        <v-btn absolute right text fab
               class="mt-1"
               @click="clean">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar>
          <v-btn-toggle v-model="article.language.id" mandatory @change="lang_art">
            <v-btn v-for="lang in article.languages" :key="lang.id"
                   :value="lang.id"
                   active-class="primary white--text"
                   class="text-uppercase">
              <v-img :src="'https://api.timetr.org' + lang.flag"
                     width="20" height="20" class="rounded-circle mr-2"/>
              {{lang.name}}
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
        <v-row no-gutters>
          <v-col cols="4">
            <img_load :img="article.data.image" width="100%" height="100%" :set_img="set_img" class="ml-5 mt-5"/>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="article.data.title" label="Название статьи" outlined class="mb-n3 mt-5 mx-5"/>
            <VueEditor v-model="article.data.text" :editor-toolbar="customToolbar" class="ma-5" style="max-height: 600px; overflow: scroll; min-width: auto"/>
          </v-col>
        </v-row>
        <v-btn @click="save_art" class="ml-5 mb-5" color="primary">
          Сохранить
        </v-btn>
      </v-card>
    </v-overlay>

    <v-overlay v-model="ov_ask" z-index="10">
      <v-card class="text-center justify-center pb-5" light>
        <v-card-title>
          Вы действительно хотите удалить <pre> </pre> <span class="primary--text"> {{n}} </span> <pre> </pre> ?
        </v-card-title>
        <v-btn @click="clean" color="primary" width="40%" class="mr-2">
          нет
        </v-btn>
        <v-btn @click="del_art(i)" width="40%" class="ml-2">
          да
        </v-btn>
      </v-card>
    </v-overlay>
    <v-bottom-sheet v-model="snackbar">
      <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
        <v-icon v-if="text === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
        <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
        <span v-if="text === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
        <span v-else>{{text}}</span>
        <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Page_num from "@/components/page_num";
import Img_load from "@/components/img_load";
export default {
name: "blog",
  components: {Img_load, Page_num, VueEditor},
  data: () => ({
    text: '',
    timeout: 1500,
    snackbar: false,
    i: 0,
    n: '',
    overlay_add: false,
    ov_ask: false,
    overlay_add_art: false,
    overlay_blog: false,
    overlay_art: false,
    art_cat: '',
    blog_title: '',
    art_title: '',
    num: 10,
    page: 1,
    num_a: 10,
    page_a: 1,
    ct: [],
    cn: 0,
    customToolbar: [
      [{header: ['false', '3']}],
      ["bold", "italic", "underline"],
      [{color: []}, {background: []}]
    ]
  }),
  computed: {
    article_cat_list() { return this.$store.getters["article/article_cat_list"] },
    article_cat() { return this.$store.getters["article/article_cat"] },
    article_list() { return this.$store.getters["article/article_list"] },
    article() { return this.$store.getters["article/article"] },
    art_put() { return this.$store.getters["article/art_put"] },
  },
  watch: {
    snackbar () {
      setTimeout(()=> {this.snackbar = false}, this.timeout)
    }
  },
  methods:{
    async start(){
      this.ct = []
      await this.$store.dispatch('article/GET_article_сat_list', {num: this.num, page: this.page}).then(() =>{
        for( let i in this.article_cat_list.data){
          this.ct.push(this.article_cat_list.data[i].title)
          //console.log(this.ct)
        }
      })
      await this.$store.dispatch('article/GET_article_list', {num: this.num_a, page: this.page_a})
    },
    clean(){
      this.blog_title = ''
      this.art_title = ''
      this.art_cat = ''
      this.overlay_add = false
      this.overlay_blog = false
      this.overlay_add_art = false
      this.overlay_art = false
      this.ov_ask = false
    },
    async add_new(){
      await this.$store.dispatch('article/POST_article_cat', {
        image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
        seo_image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
        icon: 'uploads/4dbaZxSEhAFPJufaJgfCL7iZJm6RSgO81hrhLtIg.',
        title: this.blog_title,
        seo_title: this.blog_title,
        url: this.blog_title,
        keywords:  this.blog_title,
        description: this.blog_title,
      }).then( res => {
        this.clean()
        this.start()
      })

    },
    async add_new_art(){
      await this.$store.dispatch('article/POST_article', {
        article_category_id: this.article_cat_list.data[this.ct.indexOf(this.art_cat)].id,
        article_author_id: 1,
        image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
        seo_image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
        icon: 'uploads/4dbaZxSEhAFPJufaJgfCL7iZJm6RSgO81hrhLtIg.',
        title: this.art_title,
        seo_title: this.art_title,
        url: this.art_title,
        keywords:  this.art_title,
        description: this.art_title,
        alt: this.art_title,
        text: '<p></p>',
        subtext: new Date().toISOString().substr(0, 10)
      }).then( res => {
        this.clean()
        this.start()
      })


    },
    async open_blg(item){
      await this.$store.dispatch('article/GET_article_cat',  {id: item.id, lang: 1}).then(res => {
        this.overlay_blog = true
      })

    },
    async act(id, state){
      let temp = {
        field: "is_active",
        value: state
      }
      await this.$store.dispatch('article/PUT_article_cat_field', {id: id, data: temp}).then(res => {
        //this.get_unit(id, state)
      })
    },
    async del(id){
      this.$store.dispatch("article/DELETE_article_cat", id).then( res => {
        this.start()
      })
    },

    open_art(item){
      this.$router.push('/blog/' + item.id)
     /* await this.$store.dispatch('article/GET_article',  {id: item.id, lang: 1}).then(res => {
        this.overlay_art = true
      })
      */
    },

    async act_art(id, state){
      let temp = {
        field: "is_active",
        value: state
      }
      await this.$store.dispatch('article/PUT_article_field', {id: id, data: temp})
    },

    async del_art(id){
      await this.$store.dispatch('article/DELETE_article', id).then( res => {
        this.clean()
        this.start()
      })
    },

    ask_del(id, name){
      this.i = id
      this.n = name
      this.ov_ask = true
    },

    async on_change(id){
      this.page = id
      await this.start()
    },
    async on_change_a(id){
      this.page_a = id
      await this.start()
    },
    change_num(num){
      this.num = num
      this.page = 1
      this.start()
    },
    change_numa(num){
      this.num_a = num
      this.page_a = 1
      this.start()
    },
    lang(){
      this.$store.dispatch('article/GET_article_cat',  {id: this.article_cat.data.id, lang: this.article_cat.language.id})
    },
    lang_art(){
      this.$store.dispatch('article/GET_article',  {id: this.article.data.id, lang: this.article.language.id})
    },
    async save_cat(){
      this.article_cat.data.language_id = this.article_cat.language.id
      //this.article_cat.data.article_category_id = this.article_cat.data.id
      this.article_cat.data.description = this.article_cat.data.title
      this.article_cat.data.keywords = this.article_cat.data.title
      this.article_cat.data.seo_title = this.article_cat.data.title
      this.article_cat.data.url = this.article_cat.data.title
      this.article_cat.data.image = '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png'
      await this.$store.dispatch('article/PUT_article_cat', {
        id: this.article_cat.data.id,
        data: {
          description: this.article_cat.data.title,
          icon: this.article_cat.data.icon,
          id: this.article_cat.data.id,
          image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
          keywords: this.article_cat.data.title,
          language_id: this.article_cat.language.id,
          seo_image: "/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png",
          seo_title: this.article_cat.data.title,
          title: this.article_cat.data.title,
          url: this.article_cat.data.title,
        }
      }).then(res => {
        if(this.art_put.data) {
          this.text = this.art_put.data
        }
        else this.text = this.art_put
        this.snackbar = true
        console.log(this.text)
        this.start()
      })
    },
    async save_art(){
      console.log(this.article)
      this.article.data.language_id = this.article.language.id
      this.article.data.article_category_id = this.article.article_category.id
      this.article.data.article_author_id = 1
      this.article.data.alt = this.article.data.title
      this.article.data.keywords = this.article.data.title
      this.article.data.seo_title = this.article.data.title
      this.article.data.subtext = this.article.data.title
      this.article.data.url = this.article.data.title
      this.article.data.description = this.article.data.title
      if (!this.article.data.text) {
        this.article.data.text = '<p></p>'
      }
      await this.$store.dispatch('article/PUT_article', {
        id: this.article.data.id,
        data: this.article.data
      }).then(res => {
        if(this.art_put.data) {
          this.text = this.art_put.data
        }
        else this.text = this.art_put
        this.snackbar = true
        console.log(this.text)
        this.start()
      })
    },
    select_cat(){
      if(!this.art_cat){
        this.start()
      }
      else{
        //this.article_cat_list.data[this.ct.indexOf(this.art_cat)].id
        this.$store.dispatch('article/GET_article_list_cat', {id: this.article_cat_list.data[this.ct.indexOf(this.art_cat)].id,num: this.num_a, page: this.page_a})
      }
    },
    set_img(item, img){
      this.article.data.image = img
      this.article.data.language_id = this.article.language.id
      this.article.data.article_category_id = this.article.article_category.id
      this.article.data.article_author_id = 1
      if (!this.article.data.text) {
        this.article.data.text = '<p></p>'
      }
      this.$store.dispatch('article/PUT_article', {
        id: this.article.data.id,
        data: this.article.data
      })
    },
  },
  mounted() {
    this.start()
  }
}
</script>

<style scoped>

</style>
