<template>
  <srv_test :item="this.$route.params"/>
</template>

<script>
import Srv_test from "@/components/srv_test";
export default {
name: "srv_id",
  components: {Srv_test}
}
</script>

<style scoped>

</style>
