<template>
<div v-if="page.data">
  <v-toolbar>
    SEO
    <v-spacer/>
    <v-btn-toggle v-model="page.language.id" mandatory @change="lang">
      <v-btn v-for="lang in page.languages" :key="lang.id"
             :value="lang.id"
             active-class="primary white--text"
             class="text-uppercase">
        <v-img :src="'https://api.timetr.org' + lang.flag"
               width="20" height="20" class="rounded-circle mr-2"/>
        {{lang.name}}
      </v-btn>
    </v-btn-toggle>
    <v-btn color="primary" @click="save" class="ml-5">
      Сохранить
    </v-btn>
  </v-toolbar>
  <v-row class="pa-5">
    <v-col cols="12" sm="8">
      <v-text-field v-model="page.data.seo_title"  outlined label="Название <title>"/>
      <v-textarea v-model="page.data.keywords"  outlined auto-grow label="Ключевые слова"/>
      <v-textarea v-model="page.data.description"  outlined auto-grow label="Краткое описание"/>
    </v-col>
    <v-col cols="12" sm="4">
      <img_load :img="page.data.image" width="100%" height="450" :set_img="set_img" contain/>
    </v-col>
  </v-row>
  <v-bottom-sheet v-model="snackbar">
    <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
      <v-icon v-if="text === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
      <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
      <span v-if="text === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
      <span v-else>{{text}}</span>
      <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
    </v-sheet>
  </v-bottom-sheet>
</div>
</template>

<script>
import Img_load from "@/components/img_load";
export default {
name: "ceo",
  components: {Img_load},
  computed:{
    page(){ return this.$store.getters["pages/page"] },
    page_res() { return this.$store.getters["pages/page_res"] },
  },
  data: () => ({
    text: '',
    timeout: 2000,
    snackbar: false,
    page_id: 12,
    customToolbar: [
      [{header: ['false', '3']}],
      ["bold", "italic", "underline"],
      [{  align: ""}, { align: "center"}, { align: "right" }, {align: "justify"}],
      [{color: []}, {background: []}],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ['clean']
    ]
  }),
  watch: {
    snackbar () {
      setTimeout(()=> {this.snackbar = false}, this.timeout)
    }
  },
  methods: {
    async cr() {
      await this.$store.dispatch('pages/GET_page', {id: this.page_id, lang: 1}).then(res => {
      })
    },

    async start() {
      if ( this.page.language) {
        await this.$store.dispatch('pages/GET_page', {id: this.page_id, lang: this.page.language.id}).then(res => {

        })
      }
    },

    set_img(item, img){
      this.page.data.image = img
      this.save()
    },

    async save(){
      this.page.data.language_id = this.page.language.id
      await this.$store.dispatch('pages/upd_page_changes', {id: this.page_id, page_data: this.page.data }).then( res => {
        if(this.page_res.data) {
          this.text = this.page_res.data
        }
        else this.text = this.page_res
        this.snackbar = true
        this.start()
      })
    },
    async lang(){
      await this.$store.dispatch('pages/GET_page', {id: this.page_id, lang: this.page.language.id}).then( res => {
      })
    },

  },
  created() {
    this.cr()
  },
  mounted() {
    this.start()
  }
}
</script>

<style scoped>

</style>
