<template>
    <div>
        <v-navigation-drawer app fixed color="#FAFAFA"
                             v-model="drawer"
                             :mini-variant="miniVariant"
                             :clipped="true" style="border-right: 1px solid #c1c1c1">
            <v-list>
              <v-list-item to="/" color="primary"
                           router exact>
                <v-list-item-action>
                  <v-badge v-if="feedback_count"
                           color="red" dot>
                    <v-icon>mdi-phone-message-outline</v-icon>
                  </v-badge>
                  <v-icon v-else>mdi-phone-message-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Обратная связь
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

                <v-divider/>
                <v-list-item v-for="(item, i) in nav_items" :key="i"
                             :to="item.to" color="primary"
                             router exact>
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" />
                    </v-list-item-content>
                </v-list-item>
              <v-divider/>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Переводы</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="(item, i) in qq" :key="i"
                             :to="item.to" color="primary"
                             router exact>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app fixed
                   height="90"
                   :clipped-left="true"
                   :elevation="1"
                   color="white">
            <v-btn icon
                   @click.stop="miniVariant = !miniVariant" class="hidden-md-and-down">
                <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
            </v-btn>
            <v-btn icon
                   @click.stop="drawer = !drawer" class="hidden-lg-and-up">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-btn text dark to="/">
                <v-img src="./img/logo.png" max-width="80" max-height="80"/>
            </v-btn>
            <v-spacer/>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text fab
                           v-bind="attrs" v-on="on"
                           class="text-none">
                        <v-icon>mdi-account-outline</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="set_logout" to="/">
                        <v-list-item-action>
                            <v-icon>mdi-exit-run</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Выйти</v-list-item-title>
                    </v-list-item>

                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "Header",
        data: () => ({
            miniVariant: false,
            drawer: true,
            new_order: false,
            temp:[],
            nav_items: [
              {
                icon: 'mdi-format-list-checks',
                title: 'Услуги',
                to: '/service',
              },
              {
                icon: 'mdi-alert-decagram-outline',
                title: 'Акции',
                to: '/discount',
              },
              {
                icon: 'mdi-format-letter-case',
                title: 'Блог',
                to: '/blog'
              },
              {
                icon: 'mdi-information-outline',
                title: 'О нас',
                to: '/main'
              },
              {
                icon: 'mdi-information-outline',
                title: 'О компании',
                to: '/about'
              },
              {
                icon: 'mdi-account-group-outline',
                title: 'Сотрудничество',
                to: '/cooperate'
              },
              {
                icon: 'mdi-at',
                title: 'Контакты',
                to: '/contacts'
              },
              {
                icon: 'mdi-code-tags',
                title: 'SEO',
                to: '/seo'
              },
            ],
          qq: [
            {
              icon: 'mdi-format-list-checks',
              title: 'Навигация',
              to: '/nav',
            },
            {
              icon: 'mdi-format-list-checks',
              title: 'Кнопки',
              to: '/btn',
            },
            {
              icon: 'mdi-format-list-checks',
              title: 'Контактная форма',
              to: '/modal',
            },
            {
              icon: 'mdi-format-list-checks',
              title: 'Ошибки формы',
              to: '/errors',
            },
          ]
        }),
        computed: {
            feedback_count() {
                return this.$store.getters["feedback/feedback_count"]
            },
            order_list(){
                return this.$store.getters['order/order_list_h']
            }
        },

        methods:{
            async set_logout(){
                await this.$store.dispatch('auth/logout')
                    .then(res => {
                        this.$store.dispatch('auth/get_logged_in')
                    })
            },

            async start(){
                await this.$store.dispatch('feedback/feedback_count_GET')
                setInterval( () => this.$store.dispatch('feedback/feedback_count_GET'), 30000);
            },

            test(){
                for(let item in this.order_list.data){
                    this.temp.push(this.order_list.data[item].status)
                }
                if( this.temp.indexOf('new') >= 0){
                    this.new_order = true
                }
                else {
                    this.new_order = false
                }

            },
        },
        created() {
            this.start()
        },
      mounted() {

      }
    }
</script>

<style scoped lang="scss">
    .v-app-bar{
        max-width: 100vw !important;
    }
</style>
