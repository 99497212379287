import Vue from 'vue'
import VueRouter from 'vue-router'

import service from "../views/service";
import blog from "@/views/blog";
import discount from "@/views/discount";
import review from "@/views/review";
import about from "@/views/about";
import cooperate from "@/views/cooperate";
import contacts from "@/views/contacts";
import msg from "@/views/msg";
import index from "../views/index";
import navq from "@/views/navq";
import btn from "@/views/btn";
import modal from "@/views/modal";
import errors from "@/views/errors";
import srv_id from "@/views/srv_id";
import bl_id from "@/views/bl_id";
import main from "@/views/main_about";
import txt from "@/views/txt";
import ceo from '@/views/ceo';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/service/:id',
    name: 'srv_id',
    component: srv_id
  },
  {
    path: '/blog',
    name: 'blog',
    component: blog
  },
  {
    path: '/blog/:id',
    name: 'bl_id',
    component: bl_id
  },
  {
    path: '/discount',
    name: 'discount',
    component: discount
  },
  {
    path: '/review',
    name: 'review',
    component: review
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/main',
    name: 'main',
    component: main
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: cooperate
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: contacts
  },
  {
    path: '/msg',
    name: 'msg',
    component: msg
  },
  {
    path: '/nav',
    name: 'navq',
    component: navq
  },
  {
    path: '/btn',
    name: 'btn',
    component: btn
  },
  {
    path: '/modal',
    name: 'modal',
    component: modal
  },
  {
    path: '/errors',
    name: 'errors',
    component: errors
  },
  {
    path: '/text',
    name: 'text',
    component: txt
  },
  {
    path: '/seo',
    name: 'ceo',
    component: ceo
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
