import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import auth from './modules/auth'
import feedback from './modules/feedback'
import order from './modules/order'
import service from "./modules/service"
import pages from "./modules/pages"
import interfaceG from './modules/inretface'
import article from './modules/article'
import portfolio from "@/store/modules/portfolio";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        test: localStorage.getItem('page'),
        apiDomain: 'https://api.timetr.org',
        numberOfItems: 10,
        numberOfPage: 1,
        field_id: 1,
        sort_method: 'asc',
        sort_field: 'id',
        language: {
            id: 1,
            name: 'ru'
        },
        file: '',

        apiUrls: {
            feedback: '/api/feedback',
            contact: '/api/contact',
            pages: '/api/page',
            interfaceGroup: '/api/interfaceGroup',
            interface: '/api/interface',
            text: '/api/text',
            serviceCategory: '/api/serviceCategory',
            service: '/api/service',
            order: '/api/admin/order',
            file: '/api/uploadfile',
            article: '/api/article',
            article_cat: '/api/articleCategory',
        },
    },
    actions: {
        field_id_SET ({commit}, id) {
            commit('upd_field_id', id)
        },
        numberOfItems_SET ({commit}, id) {
            commit('upd_numberOfItems', id)
        },
        numberOfPage_SET({commit}, numberOfPage){
            commit('upd_numberOfPage', numberOfPage)
        },

        async uploadFile ({commit}, {data, use_case}) {
            let url = this.state.apiDomain + this.state.apiUrls.file + '?use_case=' + use_case
            await axios.post( url, data)
                .then((response) => {
                    commit('upd_file', response.data)
                })
                .catch((error) => { console.log(error) })
        },

        async deleteFile ({commit}, info) {
            let url = this.state.apiDomain + '/api/destroyfile?url=' + info
            await axios.delete( url)
                .then((response) => {
                    console.log(response)
                    console.log(url)
                })
                .catch((error) => { console.log(error) })
        },

        set_test({commit}, info){
            localStorage.setItem('page', info)
            commit('upd_test', localStorage.getItem('page'))
        }

    },
    mutations: {
        upd_field_id: (state, item) => { state.field_id = item},
        upd_numberOfItems: (state, item) => { state.numberOfItems = item},
        upd_numberOfPage: (state, item) => { state.numberOfPage = item},
        upd_file: (state, item) => { state.file = item},

        upd_test: (state, item) => { state.test = item},
    },
    getters:{
        field_id(state) { return state.field_id },
        numberOfItems(state) { return state.numberOfItems },
        numberOfPage(state) {return state.numberOfPage},
        apiDomain(state) {return state.apiDomain},
        file_store(state) {return state.file},

        test_store(state) {return state.test}
    },
    modules: {
        portfolio,
        article,
        interfaceG,
        pages,
        service,
        order,
        feedback,
        auth,
    }
})
