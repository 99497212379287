<template>
  <div v-if="interface.data && text.data">
    <v-toolbar>
      Контакты
      <v-spacer/>
      <v-btn-toggle v-model="interface.language.id" mandatory @change="lang">
        <v-btn v-for="lang in interface.languages" :key="lang.id"
               :value="lang.id"
               active-class="primary white--text"
               class="text-uppercase">
          <v-img :src="'https://api.timetr.org' + lang.flag"
                 width="20" height="20" class="rounded-circle mr-2"/>
          {{lang.name}}
        </v-btn>
      </v-btn-toggle>
      <v-btn color="primary" @click="save" class="ml-5">
        Сохранить
      </v-btn>
    </v-toolbar>
    <v-row no-gutters class="py-5">
      <v-col cols="12" md="5">
        <div v-for="i in interface.data" :key="i.id">
          <v-text-field v-if="i.name !== 'position' && i.name !== 'schet' && i.name !== 'swift' && i.name !== 'nazvanie-banka' && i.name !== 'image' && i.name !== 'whatsapp' && i.name !== 'adres'"
                        :label="i.title" v-model="i.value" hide-details outlined dense class="mx-5 my-2"/>
          <div v-else-if="i.name === 'adres'">
            <span class="mx-5 mt-2" style="font-size: 12px">{{i.title}}</span>
            <VueEditor v-model="i.value" class="mx-5 mb-2"/>
          </div>

        </div>

        <img_load :img="interface.data[0].value" width="255" height="255" :set_img="set_img_w" class="ma-5"/>

      </v-col>
      <v-col cols="12" md="7">
        <VueEditor v-model="text.data[1].value" :editor-toolbar="customToolbar" class="mx-5 my-2"/>
        <VueEditor v-model="text.data[0].value" :editor-toolbar="customToolbar" class="mx-5 my-2"/>
        <img_load :img="interface.data[12].value" width="255" height="560" :set_img="set_img" class="ma-5"/>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="snackbar1">
      <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
        <v-icon v-if="text1 === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
        <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
        <span v-if="text1 === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
        <span v-else>{{text1}}</span>
        <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="snackbar2" v-if="text2 !== 'Successful operation'">
      <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
        <v-icon v-if="text2 === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
        <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
        <span v-if="text2 === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
        <span v-else>{{text2}}</span>
        <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Img_load from "@/components/img_load";
import { VueEditor } from "vue2-editor";
export default {
  name: "contacts",
  components: {Img_load, VueEditor},
  computed:{
    page(){ return this.$store.getters["pages/page"] },
    interfaceGroup(){ return this.$store.getters["interfaceG/interfaceGroup"] },
    interface(){ return this.$store.getters["interfaceG/interface"] },
    text(){ return this.$store.getters["interfaceG/text"] },
    int_res() { return this.$store.getters["interfaceG/int_res"] },
  },
  data: () => ({
    text1: '',
    text2: '',
    timeout: 2000,
    snackbar1: false,
    snackbar2: false,
    page_id: 11,
    customToolbar: [
      [{header: ['false', '3']}],
      ["bold", "italic", "underline"],
      [{  align: ""}, { align: "center"}, { align: "right" }, {align: "justify"}],
      [{color: []}, {background: []}],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ['clean']
    ]
  }),
  watch: {
    snackbar1 () {
      setTimeout(()=> {this.snackbar1 = false}, this.timeout)
    },
    snackbar2 () {
      setTimeout(()=> {this.snackbar2 = false}, this.timeout)
    }
  },
  methods: {

    async cr() {
      await this.$store.dispatch('interfaceG/GET_interface', {id: 22, lang: 1})
      await this.$store.dispatch('interfaceG/GET_text', {id: 22, lang: 1})
      //https://api.timetr.org/api/interface?interface_group_id=22&language_id=1
      //https://api.timetr.org/api/text?interface_group_id=22&language_id=1
    },

    async start() {
      if ( this.interface.language) {
        await this.$store.dispatch('interfaceG/GET_interface', {id: 22, lang: this.interface.language.id})
        await this.$store.dispatch('interfaceG/GET_text', {id: 22, lang: this.interface.language.id})
      }
    },

    set_img(item, img){
      this.interface.data[12].value = img
      this.save()
    },
    set_img_w(item, img){
      this.interface.data[0].value = img
      this.save()
    },

    async save(){
      let temp = {
        language_id: this.interface.language.id,
        translations: []
      }
      for (let i in this.interface.data){
        temp.translations.push({
          interface_entity_id: this.interface.data[i].id,
          value: this.interface.data[i].value
        })
      }
      await this.$store.dispatch('interfaceG/update_interfase_fields', temp).then(res => {
        if(this.int_res.status === 200) {
          this.text1 =  'Successful operation'
        }
        else this.text1 = this.int_res
        this.snackbar1 = true
      })
      temp.translations = []
      for (let i in this.text.data){
        temp.translations.push({
          text_entity_id: this.text.data[i].id,
          value: this.text.data[i].value
        })
      }
      await this.$store.dispatch('interfaceG/update_text_fields', temp).then(res => {
        if(this.int_res.status === 200) {
          this.text2 = 'Successful operation'
        }
        else this.text2 = this.int_res
        this.snackbar2 = true
      })
    },
    async lang(){
      await this.$store.dispatch('interfaceG/GET_interface', {id: 22, lang: this.interface.language.id})
      await this.$store.dispatch('interfaceG/GET_text', {id: 22, lang: this.interface.language.id})
    },

  },
  created() {
    this.cr()
  },
  mounted() {
    this.start()
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-label{
  min-width: 62px !important;
}

::v-deep .v-input__slider{
  min-width: 300px !important;
  width: 300px !important;
}
</style>
