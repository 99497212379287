import axios from "axios";

const state = {
    Pages_list: [],
    page: [],
    interfaceGroup: [],
    interface: [],
    text: [],
    page_res: [],
}

const actions = {
    async GET_pages_list ({commit}){
        let url = this.state.apiDomain + this.state.apiUrls.pages + '?page=' + this.state.numberOfPage + '&number='+ this.state.numberOfItems + '&method=' + this.state.sort_method + '&field=' + this.state.sort_field
        return await axios.get( url )
            .then((response) => {
                commit('update_pages_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },
    async GET_page ({commit}, {id, lang}){
        let url = this.state.apiDomain + this.state.apiUrls.pages + '/' + id + '/edit?language_id=' + lang
        return await axios.get( url )
            .then((response) => {
                commit('update_page', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    delete_page(){
        let url = this.state.apiDomain + this.state.apiUrls.pages +'/'+ this.state.field_id
        return axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async upd_page_changes({commit}, {id, page_data}){
        let url = this.state.apiDomain + this.state.apiUrls.pages +'/'+ id
        return axios.put( url, page_data )
            .then((response) => {
                commit('upd_page_res', response)
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
                commit('upd_page_res', error)
            })
    },
    async add_page ({commit}, page_data){
        let url = this.state.apiDomain + this.state.apiUrls.pages
        return axios.post( url, page_data )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },
}

const mutations = {
    update_pages_list: (state, item) => { state.Pages_list = item },
    update_page: (state, item) => { state.page = item },
    upd_page_res: (state, item) => { state.page_res = item },

}

const getters = {
    Pages_list(state) {return state.Pages_list},
    page(state) {return state.page},
    page_res(state) {return state.page_res},

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
