<template>
  <div v-if="page.data">
    <v-toolbar class="mb-4">
      О компании
      <v-spacer/>
      <v-btn-toggle v-model="page.language.id" mandatory @change="lang">
        <v-btn v-for="lang in page.languages" :key="lang.id"
               :value="lang.id"
               active-class="primary white--text"
               class="text-uppercase">
          <v-img :src="'https://api.timetr.org' + lang.flag"
                 width="20" height="20" class="rounded-circle mr-2"/>
          {{lang.name}}
        </v-btn>
      </v-btn-toggle>
      <v-btn color="primary" @click="save" class="ml-5">
        Сохранить
      </v-btn>
    </v-toolbar>
    <v-card class="pa-1" flat tile>
      <div class="d-flex flex-column flex-md-row justify-space-between">
        <v-card tile flat
                class="ml-5">
<!--          <v-slider v-model="page.data.keywords" validate-on-blur
                    :min="0" :max="100"
                    label="Позиция:"
                    class="align-center mt-10" >
            <template v-slot:append>
              <v-text-field v-model="page.data.keywords"
                            class="mt-0 pt-0"
                            type="number"
                            style="width: 50px"
              ></v-text-field>
            </template>
          </v-slider>-->
          <img_load :img="page.data.image" width="255" height="280" class="mx-5"  :set_img="set_img" case="icon"/>
          <div class="d-flex flex-column align-center my-2">
                    <span class="grey--text">
                      255
                      <v-icon x-small>mdi-close</v-icon>
                      280
                    </span>
<!--            <div style="width: 260px" class="grey&#45;&#45;text my-2">Рекомендуем загружать изображения размером 790<v-icon x-small>mdi-close</v-icon>560, поскольку для экранов, шириной менее 800px, изображение растягивается по ширине </div>-->
          </div>
          <img_load :img="page.data.keywords" width="255" height="280" class="mx-5"  :set_img="set_img1" case="icon"/>
          <div class="d-flex flex-column align-center my-2">
                    <span class="grey--text">
                      255
                      <v-icon x-small>mdi-close</v-icon>
                      280
                    </span>
            <!--            <div style="width: 260px" class="grey&#45;&#45;text my-2">Рекомендуем загружать изображения размером 790<v-icon x-small>mdi-close</v-icon>560, поскольку для экранов, шириной менее 800px, изображение растягивается по ширине </div>-->
          </div>
        </v-card>
        <v-card flat class="mx-5 mb-12" width="100%">
          <v-text-field v-model="page.data.title" outlined class="mb-n3 mt-3"/>
          <VueEditor v-model="page.data.description" :editor-toolbar="customToolbar"/>
        </v-card>
      </div>
    </v-card>
    <v-bottom-sheet v-model="snackbar">
      <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
        <v-icon v-if="text === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
        <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
        <span v-if="text === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
        <span v-else>{{text}}</span>
        <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Img_load from "@/components/img_load";
import { VueEditor } from "vue2-editor";
export default {
  name: "about",
  components: {Img_load, VueEditor},
  computed:{
    page(){ return this.$store.getters["pages/page"] },
    page_res() { return this.$store.getters["pages/page_res"] },
  },
  data: () => ({
    text: '',
    timeout: 2000,
    snackbar: false,
    page_id: 5,
    customToolbar: [
      [{header: ['false', '3']}],
      ["bold", "italic", "underline"],
      [{  align: ""}, { align: "center"}, { align: "right" }, {align: "justify"}],
      [{color: []}, {background: []}],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ['clean']
    ]
  }),
  watch: {
    snackbar () {
      setTimeout(()=> {this.snackbar = false}, this.timeout)
    }
  },
  methods: {
    async cr() {
      await this.$store.dispatch('pages/GET_page', {id: this.page_id, lang: 1}).then(res => {
        //this.page.data.keywords = this.page.data.keywords.split(',')
      })
    },

    async start() {
      if ( this.page.language) {
        await this.$store.dispatch('pages/GET_page', {id: this.page_id, lang: this.page.language.id}).then(res => {
          //this.page.data.keywords = this.page.data.keywords.split(',')
        })
      }
    },

    set_img(item, img){
      this.page.data.image = img
      this.save()
    },
    set_img1(item, img){
      this.page.data.keywords = img
      this.save()
    },

    async save(){
      this.page.data.language_id = this.page.language.id
      this.page.data.keywords = String(this.page.data.keywords)
      await this.$store.dispatch('pages/upd_page_changes', {id: this.page_id, page_data: this.page.data }).then( res => {
        if(this.page_res.data) {
          this.text = this.page_res.data
        }
        else this.text = this.page_res
        this.snackbar = true
        console.log(res)
        this.start()
      })
    },
    async lang(){
      await this.$store.dispatch('pages/GET_page', {id: this.page_id, lang: this.page.language.id}).then( res => {
        //this.page.data.keywords = this.page.data.keywords.split(',')
      })
    },

  },
  created() {
    this.cr()
  },
  mounted() {
    this.start()
  }
}
</script>

<style scoped lang="scss">

::v-deep .v-label{
  min-width: 62px !important;
}

::v-deep .v-input__slider{
  min-width: 300px !important;
  width: 300px !important;
}

</style>
