<template>
    <div v-if="test">
        <v-card class="ma-1 pa-1">
            <div class="d-flex align-center justify-space-between">
                <v-card width="20%">
                    <img_load :img="test.image" width="100%" height="200" :set_img="set_img"/>
                </v-card>
                <v-card flat width="70%">
                    <v-text-field v-model="test.title" label="Название" class="mb-n3"/>
                    <v-text-field v-model="test.text" label="Цена" class="mb-n3"/>
                    <v-text-field v-model="test.subtext" label="Описание" class="mb-n3"/>
                </v-card>
                <div>
                    <v-btn @click="overlay_del = true" icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-switch @click="act(test.id, srv.is_active)"
                              v-model="srv.is_active" hide-details></v-switch>
                </div>
            </div>
        </v-card>
        {{test}}
        <br/>
        <br/>

        {{srv}}
        <v-overlay z-index="10"
                   :value="overlay_del">
            <v-card class="pa-10" light>
                <v-card-title>
                    Вы действительно хотите удалить
                    <pre> </pre>
                    <span class="primary--text">{{test.title}}</span>
                    <pre> </pre>
                    ?
                </v-card-title>
                <v-row justify="space-between">
                    <v-btn @click="del" width="45%">
                        Да
                    </v-btn>
                    <v-btn @click="overlay_del = false" width="45%" class="primary">
                        Нет
                    </v-btn>
                </v-row>
            </v-card>
        </v-overlay>
    </div>
</template>

<script>
    import Img_load from "./img_load";
    export default {
        name: "srv",
        components: {Img_load},
        data: () => ({
            overlay_del: false,
            test: [],
        }),
        props: {
            S: Boolean,
            srv: {}
        },
        computed: {
            service() { return this.$store.getters["service/service"] },
            unit() { return this.$store.getters["service/unit"] },
        },
        methods: {
            async start(){
                await this.$store.dispatch('service/service_GET', this.srv.id).then( res => {
                    this.test = this.service.data
                })
            },
            async save(){
                this.test.service_category_id = 2
                this.test.seo_title = this.test.title
                this.test.url = this.test.title
                this.test.language_id = 1
                await this.$store.dispatch('service/service_PUT', {
                    id: this.test.id,
                    item: this.test
                })
            },
            async act(item, state){
                let temp = {
                    field: "is_active",
                    value: state
                }
                await this.$store.dispatch('service/serv_PUT_field', {id: item, item: temp})
                    .then(res =>{
                        this.$store.dispatch('service/unite_GET', item)
                        console.log(state)
                        console.log(this.unit)

                        this.add_unit(item, state)
                    })
            },

            set_img(item, img){
                this.test.image = img
                this.save()
            },

            async add_unit(item, state){
                if (state && this.unit === []){
                    //костыль: добавляем юнит
                    let un ={
                        name: "test",
                        location_id: 1,
                        service_id: item,
                        notes: "",
                        number: '10000'
                    }
                    await this.$store.dispatch('service/unite_POST', un)
                    console.log('+ unit')
                }
            },
            async del(){
                await this.$store.dispatch("service/service_DELETE", this.test.id).then(res => {
                    this.test = []
                })
            }
        },
        created() {
            this.start()
        }
    }
</script>

<style scoped>

</style>
