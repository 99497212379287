<template>
    <div v-if="st.data">
      <v-toolbar class="mb-4">
        <v-btn depressed large color="primary" to="/service">
          Все услуги
        </v-btn>
        <v-spacer/>
        <v-btn-toggle v-model="st.language.id" mandatory @change="lang">
          <v-btn v-for="lang in st.languages" :key="lang.id"
                 :value="lang.id"
                 active-class="primary white--text"
                 class="text-uppercase">
            <v-img :src="'https://api.timetr.org' + lang.flag"
                   width="20" height="20" class="rounded-circle mr-2"/>
            {{lang.name}}
          </v-btn>
        </v-btn-toggle>
        <v-btn depressed large color="primary" class="ml-5" @click="save">
          Сохранить
        </v-btn>
      </v-toolbar>
        <v-card class="pa-1 hidden-sm-and-down" flat tile>
            <div class="d-flex justify-space-between">
                <v-card tile flat
                        class="ml-5">
                  <v-slider v-model="st.data.keywords" validate-on-blur
                            :min="0" :max="100"
                            label="Позиция"
                            class="align-center mt-10" >
                    <template v-slot:append>
                      <v-text-field v-model="st.data.keywords"
                                    class="mt-0 pt-0"
                                    type="number"
                                    style="width: 50px"/>
                      <span class="mt-2">%</span>
                    </template>
                  </v-slider>
                  <img_load :img="st.data.image" width="255" height="560" :set_img="set_img" :left="st.data.keywords" class="mx-5"/>
                  <div class="d-flex flex-column align-center my-2">
                    <span class="grey--text">
                      255
                      <v-icon x-small>mdi-close</v-icon>
                      560
                    </span>
                    <div style="width: 260px" class="grey--text my-2">Рекомендуем загружать изображения размером 790<v-icon x-small>mdi-close</v-icon>560, поскольку для экранов, шириной менее 800px, изображение растягивается по ширине </div>
                  </div>
                </v-card>
                <v-card flat class="mx-5 mb-12" width="100%">
                  <v-text-field v-model="st.data.title" label="Название услуги" outlined class="mb-n3 mt-3"/>
                  <!-- <v-text-field v-model="st.data.alt" type="number" outlined label="Цена (руб)"/> -->
                  <VueEditor v-model="st.data.text" :editor-toolbar="customToolbar"/>
                </v-card>
            </div>
        </v-card>
        <v-card class="pa-1 hidden-md-and-up" flat tile>
            <div class="d-flex flex-column align-center justify-space-between">
                <v-card width="100%">
                 <!-- <v-slider v-model="st.data.keywords" validate-on-blur
                            :min="0" :max="100"
                            label="Позиция"
                            class="align-center mt-10" >
                    <template v-slot:append>
                      <v-text-field v-model="st.data.keywords"
                                    class="mt-0 pt-0"
                                    type="number"
                                    style="width: 50px"/>
                      <span class="mt-2">%</span>
                    </template>
                  </v-slider> -->
                  <img_load :img="st.data.image" width="100%" height="560" :set_img="set_img" :left="st.data.keywords"/>
                </v-card>
                <v-card flat width="100%">
                  <v-text-field v-model="st.data.title" label="Название услуги" outlined class="mb-n3 mt-3"/>
                  <VueEditor v-model="st.data.text" :editor-toolbar="customToolbar"/>
                </v-card>
            </div>
        </v-card>
      <v-bottom-sheet v-model="snackbar">
        <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
          <v-icon v-if="text === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
          <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
          <span v-if="text === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
          <span v-else>{{text}}</span>
          <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
        </v-sheet>
      </v-bottom-sheet>
    </div>
</template>


<script>
    import Img_load from "./img_load";
    import { VueEditor } from "vue2-editor";

    export default {
        name: "srv_test",
        components: {Img_load, VueEditor},
        data: () => ({
          text: '',
          timeout: 2000,
          snackbar: false,
           st: [],
          del_ask: false,
            overlay_del: false,
          customToolbar: [
            [{header: ['false', '3']}],
            ["bold", "italic", "underline"],
            [{  align: ""}, { align: "center"}, { align: "right" }, {align: "justify"}],
            [{color: []}, {background: []}],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["clean"]
          ]
        }),
        props: {
            item : {}
        },
        computed: {
            service() { return this.$store.getters["service/service"] },
            unit() { return this.$store.getters["service/unit"] },
          srv_put() { return this.$store.getters["service/srv_put"] },
        },
      watch: {
        snackbar () {
          setTimeout(()=> {this.snackbar = false}, this.timeout)
        }
      },
        methods:{
            async start(){
                await this.$store.dispatch('service/service_GET', {id: this.item.id, lng: 1}).then( res => {
                    this.st = this.service
                  this.$store.dispatch('service/unite_GET', this.item.id)
                })
            },

            set_img(item, img){
                this.st.data.image = img
                this.save()
            },
            set_img_d(item, img){
                this.st.data.description = img
                this.save()
            },

            async del(){
              await this.del_unit().then(res => {
                this.del_ask = false
                this.$store.dispatch("service/service_DELETE", this.st.data.id)
              })
            },

          async del_unit(){
            for (let i in this.unit.data){
              await this.$store.dispatch('service/DelUnit', this.unit.data[i].id)
            }
          },

            async act(id, state){
                let temp = {
                    field: "is_active",
                    value: state
                }
                await this.$store.dispatch('service/serv_PUT_field', {id: id, item: temp}).then(res => {
                    this.get_unit(id, state)
                })
            },

            async get_unit(id, state){
                await this.$store.dispatch('service/unite_GET', id).then(res => {
                    console.log(this.unit)
                    if( !this.unit.length && state){
                        let un ={
                            name: "test",
                            location_id: 1,
                            service_id: id,
                            notes: "",
                            number: '10000'
                        }
                        this.$store.dispatch('service/unite_POST', un)
                        console.log('+ unit')

                    }
                })
            },

          async lang(){
            await this.$store.dispatch('service/service_GET', {id: this.item.id, lng: this.st.language.id}).then( res => {
              this.st = this.service
            })
          },

            async save(){
                this.st.data.service_category_id = 2
                this.st.data.seo_title = this.st.data.title
              this.st.data.description = this.st.data.title
              this.st.data.subtext = this.st.data.title
                this.st.data.url = this.st.data.title
                this.st.data.language_id = this.st.language.id
              this.st.data.keywords = String(this.st.data.keywords)
              this.st.data.alt = String(this.st.data.keywords)
              this.st.data.icon = 'uploads/4dbaZxSEhAFPJufaJgfCL7iZJm6RSgO81hrhLtIg.'

              await this.$store.dispatch('service/service_PUT', {
                id: this.st.data.id,
                item: this.st.data
              }).then( res => {
                if(this.srv_put.data) {
                  this.text = this.srv_put.data
                }
                else this.text = this.srv_put
                this.snackbar = true
              })

            }
        },
        mounted() {
            this.start()
        }
    }
</script>

<style scoped lang="scss">
::v-deep .v-label{
  min-width: 62px !important;
}

::v-deep .v-input__slider{
  min-width: 300px !important;
  width: 300px !important;
}

</style>
