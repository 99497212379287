import axios from "axios";

const state = {
    feedback_list:[],
    feedback:[],
    feedback_count: 0,
    res: []
}
const actions = {
    async feedback_list_GET ({commit}, {num, page, type}) {
        let url = this.state.apiDomain + this.state.apiUrls.feedback + '?number=' + num + '&page=' + page + '&sort=desc&field=id&type=' + type
        await axios.get( url )
            .then((response) => {
                commit('upd_feedback_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    feedback_DELETE ({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.feedback + '/' + id
        return axios.delete(url)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async feedback_GET ({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.feedback + '/' + id + '/edit'
        await axios.get( url )
            .then((response) => {
                commit('upd_feedback', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async feedback_PUT ({commit}, {id, data}){
        let url = this.state.apiDomain + this.state.apiUrls.feedback + '/' + id
        await axios.put( url, data)
            .then((response) => {
                commit('upd_res', response)
                console.log(response)
            })
            .catch((error) => {
                commit('upd_res', error)
                console.log(error)
            })
    },

    async feedback_field_PUT({commit}, {id, data}){
        let url = this.state.apiDomain + this.state.apiUrls.feedback + '/' + id + '/field'
        await axios.put( url, data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

     feedback_count_GET ({commit}){
        let url = this.state.apiDomain + this.state.apiUrls.feedback + '/count?field=is_viewed&value=0'
         axios.get( url )
            .then((response) => {
                commit('upd_feedback_count', response.data)
            })
            .catch((error) => { console.log(error) })
    }

}
const mutations = {
    upd_feedback_list: (state, item) => { state.feedback_list = item},
    upd_feedback: (state, item) => { state.feedback = item},
    upd_feedback_count: (state, item) => { state.feedback_count = item},
    upd_res: (state, item) => { state.res = item},
}
const getters = {
    feedback_list(state) { return state.feedback_list },
    feedback(state) { return state.feedback },
    feedback_count(state) { return state.feedback_count },
    res(state) { return state.res }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
