<template>
  <v-app>
    <Login v-if="!auth" :submit="set_login"/>
    <div v-else>
      <Header/>
      <v-main>
        <router-view/>
      </v-main>
    </div>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex'
import HelloWorld from './components/HelloWorld';
  import Login from "./components/Login";
  import Header from "./components/Header";

export default {
  name: 'App',

  components: {
    Header,
    Login,
    HelloWorld,
  },
  computed:{
    auth(){
      return this.$store.getters["auth/logged_in"]
    }
  },
  methods:{
    async set_login(item){
      await this.$store.dispatch('auth/auth_POST', item)
      await this.$store.dispatch('auth/get_logged_in')
              .then(res =>{
                this.$store.dispatch('set_test', 'false')
                document.location.reload(true);
              })
    },
  },
  created() {
    this.$store.dispatch('auth/get_logged_in')
  }
};
</script>

<style scoped lang="scss">
  .v-main {
    max-width: 100vw !important;
    overflow: hidden !important;
  }
</style>
