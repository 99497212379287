import axios from "axios";

const state = {
    article_list: [],
    article: [],
    article_cat: [],
    article_cat_list: [],
    art_put: [],
}

const actions = {

    async POST_article_cat({commit}, data){
        let url = this.state.apiDomain + this.state.apiUrls.article_cat
        return await axios.post( url , data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async GET_article_сat_list ({commit}, { num, page}){
        let url = this.state.apiDomain + this.state.apiUrls.article_cat + '?number=' + num + '&page=' + page;
        return await axios.get( url )
            .then((response) => {
                commit('update_article_cat_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async DELETE_article_cat ({commit}, id) {
        let url = this.state.apiDomain + this.state.apiUrls.article_cat + '/' + id
        return await axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },


    async GET_article_list ({commit}, {id, num, page}){
        let url = this.state.apiDomain + this.state.apiUrls.article + '?number=' + num + '&page=' + page;
        return await axios.get( url )
            .then((response) => {
                commit('update_article_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    //http://api.timetr.org/api/article?number=10&page=1&article_category_id=1

    async GET_article_list_cat ({commit}, {id, num, page}){
        let url = this.state.apiDomain + this.state.apiUrls.article + '?number=' + num + '&page=' + page + '&article_category_id=' + id;
        return await axios.get( url )
            .then((response) => {
                commit('update_article_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async POST_article({commit}, data){
        let url = this.state.apiDomain + this.state.apiUrls.article
        return await axios.post( url , data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async PUT_article_field ({commit}, {id, data}){
      let url = this.state.apiDomain + this.state.apiUrls.article + '/' + id + '/field'
        return await axios.put( url, data)
            .then((response) => {
                commit('upd_art_put', response)
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
                commit('upd_art_put', error)
            })
    },

    async PUT_article_cat ({commit}, {id, data}){
        let url = this.state.apiDomain + '/api/articleCategory/' + id
        return await axios.put( url, data)
            .then((response) => {
                commit('upd_art_put', response)
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
                commit('upd_art_put', error)
            })
    },

    async PUT_article_cat_field ({commit}, {id, data}){
        let url = this.state.apiDomain + '/api/articleCategory/' + id + '/field'
        return await axios.put( url, data)
            .then((response) => {
                console.log(response)
                commit('upd_art_put', response)
            })
            .catch((error) => { console.log(error)
                commit('upd_art_put', error)})
    },

    async GET_article_cat ({commit}, {id, lang}){
        let url = this.state.apiDomain + '/api/articleCategory/'+ id + '/edit?language_id=' + lang;
        return await axios.get( url )
            .then((response) => {
                commit('update_article_cat', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async DELETE_article ({commit}, id) {
        let url = this.state.apiDomain + this.state.apiUrls.article + '/' + id
        return await axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async PUT_article ({commit}, {id, data}){
        let url = this.state.apiDomain + this.state.apiUrls.article + '/' + id
        return await axios.put( url, data)
            .then((response) => {
                console.log(response)
                commit('upd_art_put', response)
            })
            .catch((error) => { console.log(error)
                commit('upd_art_put', error)})
    },

    async GET_article ({commit}, {id, lang}){
        let url = this.state.apiDomain + this.state.apiUrls.article + '/' + id + '/edit?language_id=' + lang;
        return await axios.get( url )
            .then((response) => {
                commit('update_article', response.data)
            })
            .catch((error) => { console.log(error) })
    },

}

const mutations = {
    update_article_list: (state, item) => { state.article_list = item },
    update_article: (state, item) => { state.article = item },
    update_article_cat: (state, item) => { state.article_cat = item },
    update_article_cat_list: (state, item) => { state.article_cat_list = item },
    upd_art_put: (state, item) => { state.art_put = item },
}

const getters = {
    article_list(state) {return state.article_list},
    article(state) {return state.article},
    article_cat(state) {return state.article_cat},
    article_cat_list(state) {return state.article_cat_list},
    art_put(state) {return state.art_put},
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
