<template>
  <div>
    <v-toolbar class="mb-4">
      Обратная связь
      <v-spacer/>

    </v-toolbar>
    <page_num v-if="fb_list.meta" :on_change="change_num">
      <v-select label="Тип обратной связи"
                v-model="type"
                @change="start"
                :items="items"
                item-text="name"
                item-value="value"
                outlined hide-details dense
                class="ma-2" style="max-width: 400px"/>
      <v-pagination slot="pgn" v-if="fb_list.meta.last_page !== 1"
                    @input="on_change(fb_list.meta.current_page)"
                    v-model="fb_list.meta.current_page"
                    :length="fb_list.meta.last_page"
                    total-visible="5" circle/>
    </page_num>
    <v-toolbar color="primary">
      <v-card width="40" color="transparent" flat>
      </v-card>
      <v-card width="60" color="transparent" flat class="white--text">
        Тип
      </v-card>
      <v-card width="25%" min-width="300" color="transparent" flat class="white--text">
        Имя
      </v-card>
      <v-card width="25%" min-width="300" color="transparent" flat class="white--text hidden-xs-only">
        Телефон
      </v-card>
      <v-card width="25%" min-width="300" color="transparent" flat class="white--text hidden-sm-and-down">
        Создано
      </v-card>
      <v-card width="15%" min-width="100" color="transparent" flat class="white--text hidden-sm-and-down">
        Видимость
      </v-card>
    </v-toolbar>
    <v-list>
      <div v-for="(item, i) in fb_list.data" :key="i">
        <v-list-item link>
          <v-card :class="item.is_viewed < 1 ? 'red--text' : ''"
                  width="40" flat color="transparent">
            <div v-if="item.is_viewed < 1">
              new
            </div>
          </v-card>
          <v-card width="60" flat  color="transparent" @click="open_call(item.id)">
            <v-icon v-if="item.type === 'phone'">mdi-phone-outline</v-icon>
            <v-icon v-else>mdi-email-outline</v-icon>
          </v-card>
          <v-card width="25%" min-width="300" flat  color="transparent" @click="open_call(item.id)">
            {{item.name}}
          </v-card>
          <v-card width="25%" min-width="300" flat color="transparent"  @click="open_call(item.id)" class="hidden-xs-only">
            {{item.phone}}
          </v-card>
          <v-card width="25%" min-width="300" flat color="transparent"  @click="open_call(item.id)" class="hidden-sm-and-down">
            {{item.created_at}}
          </v-card>
          <v-card width="15%" min-width="100" flat color="transparent" class="hidden-sm-and-down">
            <v-switch v-if="item.type !== 'phone'" @change="change_type(item)" style="margin-top: 0"
                      v-model="item.type" hide-details
                      :input-value="item.type" false-value="message" true-value="show"/>
          </v-card>
          <v-btn @click="ask_del(item)" fab text absolute right>
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider/>
      </div>
    </v-list>
    <v-dialog v-model="modal" width="450">
      <v-card class="pa-10 text-center">
        Вы действительно хотите удалить обратную связь от <span class="primary--text">{{name}}</span> ?
        <div class="d-flex justify-space-around align-center pt-5">
          <v-btn @click="modal = false" width="30%">
            Нет
          </v-btn>
          <v-btn @click="del(id)" width="30%" color="primary">
            Да
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-overlay v-model="open" z-index="10" v-if="fb.data">
      <v-card style="background-color: white !important;" class="pa-10" width="600" light>
        <v-btn text icon absolute top right @click="bbb">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="d-flex mb-n3">
          <v-img :src="'https://api.timetr.org/' + fb.data.image" v-if="fb.data.message !== 'call me'"
                 max-width="78" height="78"
                 class="rounded-circle">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-spacer/>
          <div class="grey--text3">
            Язык:
          </div>
          <v-img :src="'https://api.timetr.org'+ fb.data.language.flag"
                 max-width="25" height="25"
                 class="rounded-circle mx-3"/>
        </div>
        <v-text-field v-model="fb.data.name"
                      label="Имя"
                      hide-details outlined
                      class="mt-5 mb-3"/>
        <v-text-field v-model="fb.data.phone"
                      label="Телефон"
                      hide-details outlined
                      class="mb-3"/>
        <v-text-field v-model="fb.data.email"
                      label="Email"
                      hide-details outlined
                      class="mb-3"/>
        <v-text-field v-if="fb.data.message !== 'call me'"
                      v-model="fb.data.city"
                    label="Город"
                    outlined hide-details
                      class="mb-3"/>
        <v-text-field v-model="fb.data.created_at"
                      label="Создано"
                      disabled hide-details outlined
                      class="mb-3"/>
        <v-textarea v-if="fb.data.message !== 'call me'"
                    v-model="fb.data.message"
                    label="Сообщение"
                    outlined hide-details auto-grow
                    class="mb-3"/>
        <v-btn block @click="save_call">
          Сохранить
        </v-btn>
      </v-card>
    </v-overlay>
    <v-snackbar v-model="snackbar"
                color="primary"
                right
                :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Page_num from "@/components/page_num";
export default {
name: "index",
  components: {Page_num},
  data: () => ({
    text: '',
    timeout: 1500,
    snackbar: false,
    modal: false,
    name: '',
    id: 0,
    num: 10,
    page: 1,
    call_id: 0,
    open: false,
    fba: {},
    type: '',
    items: [
      {name: 'Все', value: ''},
      {name: 'Звонки', value: 'phone'},
      {name: 'Сообщения', value: 'message'},
      {name: 'Видимые сообщения', value: 'show'}
    ]
  }),

  computed: {
    fb_list() { return this.$store.getters["feedback/feedback_list"] },
    fb() { return this.$store.getters["feedback/feedback"] },
    res() { return this.$store.getters["feedback/res"] },
    feedback_count() {
      return this.$store.getters["feedback/feedback_count"]
    },
  },
  watch:{
    feedback_count(){
      this.start()
    }
  },
  methods:{
    change_type(item){
      this.$store.dispatch('feedback/feedback_field_PUT', { id: item.id, data: {
          field: 'type',
          value: item.type
        }})
    },

    start(){
      this.$store.dispatch('feedback/feedback_list_GET', {num: this.num, page: this.page, type: this.type})

    },

    on_change(id){
      this.page = id
      this.start()
    },


    change_num(num){
      this.num = num
      this.page = 1
      this.start()
    },

    ask_del(item){
      this.id = item.id
      this.name = item.name
      this.modal = true
    },

    async del(id){
      await this.$store.dispatch("feedback/feedback_DELETE", id).then(()=>{
        this.start()
      })
      this.modal = false
    },

    open_call(id){
        this.$store.dispatch('feedback/feedback_GET', id)
        this.call_id = id
      this.open = true
    },

    async save_call(){

      let temp = {
        language_id: this.fb.data.language.id,
        name: this.fb.data.name,
        email: this.fb.data.email,
        phone: this.fb.data.phone,
        message: this.fb.data.message,
        comment: '-',
      }

      await this.$store.dispatch('feedback/feedback_PUT', {id: this.fb.data.id, data: temp}).then(res => {
        if(this.res.data) {
          this.text = this.res.data
        }
        else this.text = this.res
        this.snackbar = true
      })

    },

    bbb(){
      this.start()
      this.open = false
      this.$store.dispatch('feedback/feedback_count_GET')
    }
  },

  created() {
    this.start()
  }
}
</script>

<style scoped>

</style>
