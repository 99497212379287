import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import uk from 'vuetify/es5/locale/uk';

Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#607D8B',
            }
        }
    },
    lang: {
      locales: { uk },
      current: 'uk',
    },
});
