<template>
  <div>
    <v-toolbar>
      Заказ звонка
    </v-toolbar>
    <page_num v-if="fb_list.meta" :on_change="change_num">
      <v-pagination slot="pgn" v-if="fb_list.meta.last_page !== 1"
                    @input="on_change(fb_list.meta.current_page)"
                    v-model="fb_list.meta.current_page"
                    :length="fb_list.meta.last_page"
                    total-visible="5" circle/>
    </page_num>
    <v-toolbar color="primary">
      <v-card width="40" color="transparent" flat>
      </v-card>
      <v-card width="30%" min-width="300" color="transparent" flat class="white--text" >
        Имя
      </v-card>
      <v-card width="30%" min-width="300" color="transparent" flat class="white--text" >
        Телефон
      </v-card>
      <v-card width="30%" min-width="300" color="transparent" flat class="white--text" >
        Создано
      </v-card>
    </v-toolbar>

    <v-list>
      <div v-for="(item, i) in fb_list.data" :key="i" v-if="item.type !== 'phone'">
        <v-list-item link>
          <v-card :class="item.is_viewed < 1 ? 'red--text' : ''"
                  width="40" flat color="transparent">
            <div v-if="item.is_viewed < 1">
              new
            </div>
          </v-card>
          <v-card width="30%" min-width="300" flat  color="transparent" @click="open_call(item.id)">
            {{item.name}}
          </v-card>
          <v-card width="30%" min-width="300" flat color="transparent"  @click="open_call(item.id)">
            {{item.phone}}
          </v-card>
          <v-card width="30%" min-width="300" flat color="transparent"  @click="open_call(item.id)">
            {{item.created_at}}
          </v-card>
          <v-btn @click="del(item.id)" fab text absolute right>
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider/>
      </div>
    </v-list>
    <v-overlay v-model="open" z-index="10" v-if="fb.data">
      <v-card style="background-color: white !important;" class="pa-10" width="500" light>
        <v-btn text icon absolute top right @click="open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-text-field v-model="fb.data.name"
                      label="Имя"
                      outlined class="mt-2"/>
        <v-text-field v-model="fb.data.phone"
                      label="Телефон"
                      outlined/>
        <v-text-field v-model="fb.data.created_at"
                      label="Создано" disabled
                      outlined/>
        <v-img :src="'https://api.timetr.org' + fb.data.language.flag"
               width="25" height="25"
               class="rounded-circle mt-n5 mb-4"/>
        <v-textarea v-model="fb.data.message"
                      label="Сообщение"
                      outlined/>
        <v-btn block @click="save_call" disabled>
          Сохранить
        </v-btn>
      </v-card>
    </v-overlay>

  </div>
</template>

<script>
import Page_num from "@/components/page_num";
export default {
  name: "msg",
  components: {Page_num},
  data: () => ({
    num: 10,
    page: 1,
    call_id: 0,
    open: false,
    fba: {}
  }),

  computed: {
    fb_list() { return this.$store.getters["feedback/feedback_list"] },
    fb() { return this.$store.getters["feedback/feedback"] },
  },

  methods:{
    start(){
      this.$store.dispatch('feedback/feedback_list_GET', {num: this.num, page: this.page})
    },

    on_change(id){
      this.page = id
      this.start()
    },


    change_num(num){
      this.num = num
      this.page = 1
      this.start()
    },

    del(id){
      this.$store.dispatch("feedback/feedback_DELETE", id)
      this.start()
    },

    open_call(id){
      this.$store.dispatch('feedback/feedback_GET', id)
      this.call_id = id
      this.open = true
    },

    save_call(){

      let temp = {
        language_id: 1,
        name: this.fb.data.name,
        email: this.fb.data.email,
        phone: this.fb.data.phone,
        message: this.fb.data.message,
        comment: ''
      }

      this.$store.dispatch('feedback/feedback_PUT', {id: this.fb.data.id, data: temp})

    }
  },

  created() {
    this.start()
  }
}
</script>

<style scoped>

</style>
