import axios from "axios";

const state = {
    service_list: [],
    service: [],
    service_category_list: [],
    service_category: [],
    unit:[],
    srv_put:[]
}
const actions = {
    async service_list_GET ({commit}) { //'/grid?limit=25&page=1&order[]=created_at:desc'
        let url = this.state.apiDomain + this.state.apiUrls.service + '?number=50&page=1&method=asc&field=id';
        await axios.get( url )
            .then((response) => {
                commit('upd_service_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    //http://api.tvvqs.fi/api/service?page=1&number=10&method=desc&field=id&service_category_id=6

    async NEW_service_list_GET ({commit}, {cat, num, page}) {
        let url = this.state.apiDomain + this.state.apiUrls.service + '?service_category_id='+ cat + '&number=' + num + '&page=' + page;
        await axios.get( url )
            .then((response) => {
                commit('upd_service_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async service_GET({commit}, {id, lng}){
        let url = this.state.apiDomain + this.state.apiUrls.service +'/'+ id + '/edit?language_id=' + lng
        return await axios.get( url )
            .then((response) => {
                commit('upd_service', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    service_DELETE({commit}, id){
        let url = this.state.apiDomain + this.state.apiUrls.service +'/'+ id
        return axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async service_PUT ({commit}, {id, item}){
        let url = this.state.apiDomain + this.state.apiUrls.service +'/'+ id
        return await axios.put( url , item)
            .then((response) => {
                console.log(response.data)
                commit('upd_srv_put', response)
            })
            .catch((error) => {
                console.log(error)
                commit('upd_srv_put', error)
            })
    },

    async service_POST({commit}, item){
        let url = this.state.apiDomain + this.state.apiUrls.service
        return axios.post( url , item)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async serv_cat_list_GET ({commit}){
        let url = this.state.apiDomain + this.state.apiUrls.serviceCategory + '?number=' + this.state.numberOfItems + '&page='  + this.state.numberOfPage + '&method=asc&field=id';
        return await axios.get( url )
            .then((response) => {
                commit('upd_service_category_list', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async serv_cat_GET({commit}, {id, lang}){
        let url = this.state.apiDomain+ '/api/serviceCategory/' + id + '/edit?language_id=' + lang
        return await axios.get( url )
            .then((response) => {
                commit('upd_service_category', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async serv_cat_POST({commit}, item){
        let url = this.state.apiDomain + this.state.apiUrls.serviceCategory
        return axios.post( url , item)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async serv_cat_PUT ({commit}, {id, item}){
        let url = this.state.apiDomain + '/api/serviceCategory/' + id
        return await axios.put( url , item)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => { console.log(error) })
    },

    serv_cat_DELETE(){
        let url = this.state.apiDomain + '/api/serviceCategory/' + this.state.field_id
        return axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },

    async serv_cat_PUT_field({commit}, {id, item}){
        let url = this.state.apiDomain + '/api/serviceCategory/' + id + '/field'
        await axios.put( url, item)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async serv_PUT_field({commit}, {id, item}){
        let url = this.state.apiDomain + '/api/service/' + id + '/field'
        await axios.put( url, item)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async unite_GET({commit}, id){
        let url = this.state.apiDomain + '/api/admin/service-unit/grid?service_id=' + id
        await axios.get( url )
            .then((response) => {
                commit('upd_unit', response.data)
            })
            .catch((error) => { console.log(error) })
    },


    async unite_POST({commit}, item){
        let url = this.state.apiDomain + '/api/admin/service-unit'
        await axios.post( url , item)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => { console.log(error) })
    },

    async DelUnit({commit}, id){
        let url = this.state.apiDomain + '/api/admin/service-unit/' + id
        await axios.delete( url )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    }

}
const mutations = {
    upd_service_list: (state, item) => { state.service_list = item},
    upd_service: (state, item) => { state.service = item},
    upd_service_category_list: (state, item) => { state.service_category_list = item},
    upd_service_category: (state, item) => { state.service_category = item},
    upd_unit: (state, item) => { state.unit = item},
    upd_srv_put: (state, item) => { state.srv_put = item },
}
const getters = {
    service_list(state) { return state.service_list },
    service(state) { return state.service },
    service_category_list(state) { return state.service_category_list },
    service_category(state) { return state.service_category },
    unit(state) { return state.unit },
    srv_put(state) { return state.srv_put },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
