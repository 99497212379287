<template>
  <div v-if="interface.data">
    <v-toolbar>
      Ошибки контактной формы
      <v-spacer/>
      <v-btn-toggle v-model="interface.language.id" mandatory @change="lang">
        <v-btn v-for="lang in interface.languages" :key="lang.id"
               :value="lang.id"
               active-class="primary white--text"
               class="text-uppercase">
          <v-img :src="'https://api.timetr.org' + lang.flag"
                 width="20" height="20" class="rounded-circle mr-2"/>
          {{lang.name}}
        </v-btn>
      </v-btn-toggle>
      <v-btn color="primary" @click="save" class="ml-5">
        Сохранить
      </v-btn>
    </v-toolbar>
    <v-row class="pa-5">
      <v-col cols="12">
        <div v-for="(item , i) in interface.data.length" :key="i">
          <v-text-field v-model="interface.data[item-1].value"
                        :label="interface.data[item-1].title"/>
        </div>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="snackbar1">
      <v-sheet class="d-flex flex-column text-center justify-space-around pa-4" height="200px" color="primary" dark>
        <v-icon v-if="text1 === 'Successful operation'" size="50">mdi-check-circle-outline</v-icon>
        <v-icon v-else size="50" class="red--text">mdi-alert-circle-outline</v-icon>
        <span v-if="text1 === 'Successful operation'" class="text-uppercase title">Изменения успешно сохранены</span>
        <span v-else>{{text1}}</span>
        <span class="text-center justify-center">Окно автоматически закроется через несколько секунд</span>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Img_load from "@/components/img_load";
import { VueEditor } from "vue2-editor";
export default {
  name: "errors",
  components: {Img_load, VueEditor},
  computed:{
    page(){ return this.$store.getters["pages/page"] },
    interfaceGroup(){ return this.$store.getters["interfaceG/interfaceGroup"] },
    interface(){ return this.$store.getters["interfaceG/interface"] },
    int_res() { return this.$store.getters["interfaceG/int_res"] },
  },
  data: () => ({
    text1: '',
    text2: '',
    timeout: 2000,
    snackbar1: false,
    snackbar2: false,
    customToolbar: [
      [{header: ['false', '3']}],
      ["bold", "italic", "underline"],
      [{color: []}, {background: []}]
    ]
  }),
  watch: {
    snackbar1 () {
      setTimeout(()=> {this.snackbar1 = false}, this.timeout)
    }
  },
  methods: {
    async start() {
      await this.$store.dispatch('interfaceG/GET_interface', {id: 21, lang: 1})
    },

    set_img(item, img, i){
      this.interface.data[i-1].value = img
      this.save()
    },

    async save(){
      let temp = {
        language_id: this.interface.language.id,
        translations: []
      }
      for (let i in this.interface.data){
        temp.translations.push(
            {
              interface_entity_id: this.interface.data[i].id,
              value: this.interface.data[i].value
            }
        )
      }
      await this.$store.dispatch('interfaceG/update_interfase_fields',  temp).then(res => {
        if(this.int_res.status === 200) {
          this.text1 =  'Successful operation'
        }
        else this.text1 = this.int_res
        this.snackbar1 = true
      })
    },

    lang(){
      this.$store.dispatch('interfaceG/GET_interface', {id: 21, lang: this.interface.language.id})
    },

  },
  mounted() {
    this.start()
  }
}
</script>

<style scoped>

</style>
