import axios from "axios";

const state = {
    prt: [],
    p: []
}

const actions = {
    async POST_prt ({commit}, data){
        let url = this.state.apiDomain + '/api/portfolioCategory'
        return await axios.post( url , data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },
    async GET_prt ({commit}, { num, page }){
        let url = this.state.apiDomain + '/api/portfolioCategory'
        return await axios.get( url )
            .then((response) => {
                commit('update_prt', response.data)
            })
            .catch((error) => { console.log(error) })
    },
    async PUT_prt_field ({commit}, {id, data}){
        let url = this.state.apiDomain + '/api/portfolioCategory/' + id + '/field'
        return await axios.put( url, data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },
    async POST_p ({commit}, data){
        let url = this.state.apiDomain + '/api/portfolioArticle'
        return await axios.post( url , data)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => { console.log(error) })
    },
    async GET_p ({commit}, { num, page }){
        let url = this.state.apiDomain + '/api/portfolioArticle'
        return await axios.get( url )
            .then((response) => {
                commit('update_p', response.data)
            })
            .catch((error) => { console.log(error) })
    },

    //http://api.timetr.org/api/portfolioCategory
}

const mutations = {
    update_prt: (state, item) => { state.prt = item },
    update_p: (state, item) => { state.p = item },

}

const getters = {
    prt(state) {return state.prt},
    p(state) {return state.p}
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
/*
   let qq ={
      url: "Акции",
      title: "Акции",
      seo_title: "Акции",
      keywords: "Акции",
      description: "Акции",
      image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png'
    }
    this.$store.dispatch('portfolio/POST_prt', qq)
    this.$store.dispatch('portfolio/GET_prt', { num: 10, page: 1})


let temp = {
        field: "is_active",
        value: state
      }
      await this.$store.dispatch('article/PUT_article_field', {id: id, data: temp})



      {
  "portfolio_category_id": 0,
  "article_author_id": 0,
  "image": "string",
  "seo_image": "string",
  "alt": "string",
  "url": "string",
  "title": "string",
  "seo_title": "string",
  "keywords": "string",
  "description": "string",
  "left_text": "string",
  "right_text": "string",
  "subtext": "string"
}


this.$store.dispatch('portfolio/POST_p',  temp)

let temp = {
    "portfolio_category_id": 1,
    "article_author_id": 1,
    "image": "/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png",
    "seo_image": "/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png",
    "alt": "Акции",
    "url": "Акции",
    "title": "Акции",
    "seo_title": "Акции",
    "keywords": "Акции",
    "description": "Акции",
    "left_text": "Акции",
    "right_text": "Акции",
    "subtext": "Акции"
}
await this.$store.dispatch('portfolio/GET_prt', { num: 10, page: 1}).then( res => {
    this.$store.dispatch('portfolio/GET_p', { num: 10, page: 1})
})
}
 */
