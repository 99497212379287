import axios from "axios";

const state = {
    logged_in: false,
}

const actions = {

    get_logged_in({commit}){
        if (localStorage.getItem('token')){
            commit('upd_logged_in', true)
        }
        else{
            commit('upd_logged_in', false)
        }
    },

    async auth_POST({commit}, data){
        let url = this.state.apiDomain + '/oauth/token'
        await axios.post( url, data)
            .then((response) => {
                console.log(response)
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('token_type', response.data.token_type)
                axios.defaults.headers.common['Authorization'] = response.data.access_token
                commit('upd_logged_in', true)
            })
            .catch((error) => { console.log(error) })
    },

    logout (){
        localStorage.clear()
    }
}

const mutations = {
    upd_logged_in: (state, item) => { state.logged_in = item},
}

const getters = {
    logged_in(state) { return state.logged_in }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
