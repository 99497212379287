<template>
  <v-row justify="space-between" no-gutters>
    <slot/>
    <v-row justify="end" no-gutters>
        <v-menu v-if="!off">
            <template v-slot:activator="{ on, attrs }">
                <v-btn text class="mt-2"
                       v-bind="attrs"
                       v-on="on">
                    {{items_p[item_p].title}}
                    <v-icon>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list elevation="2">
                <div v-for="j in items_p" :key="j.id">
                    <v-list-item v-if="item_p !== j.id"
                                 @click="set_number_of_items(j.id, j.title)">
                        <v-list-item-title>
                            <v-card flat class="black--text text-center">
                                {{ j.title }}
                            </v-card>
                        </v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-menu>
        <slot name="pgn"/>
    </v-row>
  </v-row>
</template>

<script>
    export default {
        name: "page_num",
        data: () => ({
            item_p: 1,
            items_p: [
                { id: 0, title: '5' },
                { id: 1, title: '10' },
                { id: 2, title: '20' },
                { id: 3, title: '50' },
            ],
        }),
        props: {
           on_change: {},
          off: Boolean
        },
        methods: {
            set_number_of_items(id, number){
                this.item_p = id
                this.on_change(number)
            }
        }
    }
</script>

<style scoped>

</style>
