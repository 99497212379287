<template>
  <div v-if="interfaceGroup.data">
    <v-toolbar>
      Отзывы
      <v-spacer/>
      <page_num v-if="interfaceGroup.data" :on_change="change_num" off>
        <v-pagination slot="pgn"
                      @input="on_change(interfaceGroup.current_page)"
                      v-model="interfaceGroup.current_page"
                      :length="interfaceGroup.last_page"
                      total-visible="5" circle/>
      </page_num>
      <v-btn @click="overlay_add = true" color="primary">
        Добавить
      </v-btn>
    </v-toolbar>
    <v-list>
      <div v-for="(item, i) in interfaceGroup.data" :key="i">
        <v-list-item link>
          <v-card flat
                  width="calc(100% - 200px)"
                  @click="open_r(item)">
            {{item.title}}
          </v-card>
          <v-spacer/>
          <v-btn @click="del(item)" fab text>
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider/>
      </div>
    </v-list>
    <v-dialog v-model="dialog" width="80%" style="overflow: hidden">
      <v-card v-if="dialog">
        <v-toolbar>
          <v-btn-toggle v-model="interface.language.id" mandatory @change="lang">
            <v-btn v-for="lang in interface.languages" :key="lang.id"
                   :value="lang.id"
                   active-class="primary white--text"
                   class="text-uppercase">
              <v-img :src="'https://api.timetr.org' + lang.flag"
                     width="20" height="20" class="rounded-circle mr-2"/>
              {{lang.name}}
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
        <v-row class="pa-5">
          <v-col cols="12" sm="3" md="2">
            <div v-for="(item , i) in interface.data.length" :key="i">
              <img_load v-if="interface.data[item-1].name === 'image'" :img="interface.data[item-1].value"
                        width="100%" height="200" contain :i="item" :set_img="set_img"/>
            </div>
          </v-col>
          <v-col cols="12" sm="9" md="10">
            <div v-for="(item , i) in interface.data.length" :key="i">
              <v-text-field v-if="interface.data[item-1].name !== 'image'"
                            v-model="interface.data[item-1].value"
                            :label="interface.data[item-1].title"/>
            </div>
            <v-textarea v-if="text.data" v-model="text.data[0].value" label="review"/>
          </v-col>
        </v-row>
        <v-btn @click="dialog = false" fab text absolute top right class="mt-9" >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn absolute top right
               @click="save"
               color="primary"
               class="mr-14">
          Сохранить
        </v-btn>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay_add" z-index="10">
      <v-card class="pa-10" light width="550">
        <v-btn absolute right text fab
               class="mt-n10"
               @click="clean">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Введите имя клиента
        </v-card-title>
        <v-text-field v-model="qq"
                      outlined/>
        <v-btn @click="add_new"
               block
               :disabled="qq ? false : true">
          OK
        </v-btn>
      </v-card>
    </v-overlay>
    <v-overlay :value="overlay_del" z-index="10">
      <v-card class="pa-10 text-center justify-center" light width="550">
        <v-card-title class="justify-center">
          Вы действительно хотите удалить <pre class="primary--text"> {{qq}} </pre> ?
        </v-card-title>
        <div class="d-flex justify-space-around">
          <v-btn @click="del_q" width="200">Да</v-btn>
          <v-btn @click="overlay_del=false" color="primary" width="200">Нет</v-btn>
        </div>
      </v-card>
    </v-overlay>
    <v-overlay :value="tm">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Img_load from "@/components/img_load";
import Page_num from "@/components/page_num";
export default {
  name: "review",
  components: {Page_num, Img_load},
  computed:{
    interfaceGroup(){ return this.$store.getters["interfaceG/interfaceGroup"] },
    interface(){ return this.$store.getters["interfaceG/interface"] },
    text(){ return this.$store.getters["interfaceG/text"] }
  },
  data: () => ({
    r_title: '',
    qq: '',
    id: 0,
    overlay_add: false,
    overlay_del: false,
    num: 10,
    page: 1,
    dialog: false,
    srv: {},
    tm: false

  }),
  watch: {
    tm (val) {
      val && setTimeout(() => {
        this.tm = false
      }, 3000)
    },
  },
  methods: {
    async start() {
      await this.$store.dispatch('interfaceG/GET_interfaceGroup', {id: 8, page: this.page, num: this.num})

    },

    clean(){
      this.srv_title = ''
      this.overlay_add = false
    },

    set_img(item, img, i){
      this.interface.data[i-1].value = img
      this.save()
    },

     save(){
      let temp = {
        language_id: this.interface.language.id,
        translations: []
      }
      for (let i in this.interface.data){
        temp.translations.push(
            {
              interface_entity_id: this.interface.data[i].id,
              value: this.interface.data[i].value
            }
        )
        this.$store.dispatch('interfaceG/update_interfase_fields',  temp)
      }
       temp.translations= [{
         text_entity_id: this.text.data[0].id,
         value: this.text.data[0].value
       }]
       this.$store.dispatch('interfaceG/update_text_fields',  temp)
    },

    async lang(){
      await this.$store.dispatch('interfaceG/GET_interface', {id: this.id, lang: this.interface.language.id}).then( res => {
        this.$store.dispatch('interfaceG/GET_text', {id: this.id, lang: this.interface.language.id})
      })
    },
    async on_change(id){
      this.page = id
      await this.start()

    },


    change_num(num){
      this.num = num
      this.page = 1
      this.start()
    },

    async open_r(item){
      this.id = item.id
      await this.$store.dispatch('interfaceG/GET_interface', {id: item.id, lang: 1}).then (res => {
        this.$store.dispatch('interfaceG/GET_text', {id: item.id, lang: 1})
        if(this.interface.data.length === 0){
          this.new_plus(item)
        }
        else{
          this.dialog = true
        }
      })
    },

    async new_plus(item){
      let temp = {
        title: 'name',
        value: item.title,
        interface_group_id: item.id,
        language_id: 1
      }
      await this.$store.dispatch('interfaceG/POST_interface', temp)
      temp = {
        title: 'map',
        value: 'null',
        interface_group_id: item.id,
        language_id: 1
      }
      await this.$store.dispatch('interfaceG/POST_interface', temp)
      temp = {
        title: 'image',
        value: 'uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
        interface_group_id: item.id,
        language_id: 1
      }
      await this.$store.dispatch('interfaceG/POST_interface', temp)
      temp = {
        title: 'review',
        value: 'null',
        interface_group_id: item.id,
        language_id: 1
      }
      await this.$store.dispatch('interfaceG/POST_text', temp).then (res => {
        this.open_r(item)
      })
    },

    del(item){
      console.log(item)
      this.id = item.id
      this.qq = item.title
      this.overlay_del = true
    },
    async del_q(){
      await this.$store.dispatch('interfaceG/delete_interfaceGroup', this.id).then(res => {
        this.start()
        this.overlay_del = false
        this.qq = ''
      })
    },
    async add_new(){
      let temp = {
        page_id: 8,
        title: this.qq
      }
      await this.$store.dispatch('interfaceG/POST_interfaceGroup', temp)
          .then( res => {
            this.start()
            this.overlay_add = false
            this.qq = ''
          })
    }

  },
  mounted() {
    this.start()
  }
}
</script>

<style scoped>

</style>
