<template>
    <div>
        <v-toolbar>
            Услуги
            <v-spacer/>
            <v-btn @click="overlay_add = true" light x-large color="primary" class="mr-2">
                Добавить
            </v-btn>
        </v-toolbar>
        <page_num v-if="service_list.meta" :on_change="change_num">
            <v-pagination slot="pgn" v-if="service_list.meta.last_page !== 1"
                          @input="on_change(service_list.meta.current_page)"
                          v-model="service_list.meta.current_page"
                          :length="service_list.meta.last_page"
                          total-visible="5" circle/>
          <v-btn class="mt-3 ml-5"
                 to="/text"
                 color="color primary">
            Редактировать статью
          </v-btn>
        </page_num>
            <v-list>
                <div v-for="(item, i) in service_list.data" :key="i">
                    <v-list-item link>
                        <v-card flat
                                width="calc(100% - 200px)"
                                @click="open_srv(item)">
                            {{item.name}}
                        </v-card>
                        <v-spacer/>
                      <div class="d-flex px-2">
                        <v-img v-for="im in  item.languages"
                               :key="im.id" height="22" width="22"  class="rounded-circle ml-1"
                               :src="'https://api.timetr.org/' + im.flag"/>
                      </div>
                        <v-switch @click="act(item.id, item.is_active)"
                                  v-model="item.is_active" hide-details class="mt-n1"></v-switch>
                        <v-btn @click="del(item)" fab text>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </v-list-item>
                    <v-divider/>
                </div>
            </v-list>
        <v-dialog v-model="dialog" width="80%" style="overflow: hidden">
            <v-card v-if="dialog">
                <srv_test :item="srv" ref="servqq"/>
                <v-btn @click="dialog = false" fab text absolute top right class="mt-9" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
              <v-btn absolute top right
                     @click="save_me"
                     color="primary"
                     class="mr-14">
                Сохранить
              </v-btn>
            </v-card>
        </v-dialog>
        <v-overlay :value="overlay_add" z-index="10">
            <v-card class="pa-10" light width="550">
                <v-btn absolute right text fab
                       class="mt-n10"
                       @click="clean">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title>
                    Введите название услуги
                </v-card-title>
                <v-text-field v-model="srv_title"
                              outlined/>
                <v-btn @click="add_new"
                       block
                       :disabled="srv_title ? false : true">
                    OK
                </v-btn>
            </v-card>
        </v-overlay>
      <v-overlay v-model="del_ask" z-index="15">
        <v-card class="text-center justify-center pb-5" light>
          <v-card-title>
            Вы действительно хотите удалить <pre> </pre> <span class="primary--text"> {{qq.name}} </span> <pre> </pre> ?
          </v-card-title>
          <v-btn @click="del_ask = false" color="primary" width="40%" class="mr-2">
            нет
          </v-btn>
          <v-btn @click="del_me" width="40%" class="ml-2">
            да
          </v-btn>
        </v-card>
      </v-overlay>
    </div>
</template>

<script>
    import srv from '../components/srv'
    import Srv_test from "../components/srv_test";
    import Page_num from "../components/page_num";
    export default {
        name: "service",
        components: {Page_num, Srv_test, srv},
        data: () => ({
            srv_title: '',
            overlay_add: false,
            num: 10,
            page: 1,
            dialog: false,
            srv: {},
          del_ask: false,
          n: '',
          qq: {}
        }),
        computed: {
            service_list() { return this.$store.getters["service/service_list"] },
          unit() { return this.$store.getters["service/unit"] },
            //cat() { return this.$store.getters["service/service_category"] },
        },
        methods:{
            async start(){
                await this.$store.dispatch('service/NEW_service_list_GET', {cat: 2, num: this.num, page: this.page})
                //await this.$store.dispatch('service/serv_cat_GET', 8)
            },
            save_all(){
                for (let i in this.service_list.data){
                    this.$refs.s[i].save()
                }
                this.cat.data.language_id = 1
                this.cat.data.icon = 'uploads/4dbaZxSEhAFPJufaJgfCL7iZJm6RSgO81hrhLtIg.'
                this.cat.data.image = '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png'
                //this.$store.dispatch('service/serv_cat_PUT', {
                //    id: this.cat.data.id,
                //    item: this.cat.data
                //})
            },
            clean(){
                this.srv_title = ''
                this.overlay_add = false
            },

            async add_new(){
                await this.$store.dispatch('service/service_POST', {
                    image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
                    service_category_id: 2,
                    seo_image: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
                    icon: 'uploads/4dbaZxSEhAFPJufaJgfCL7iZJm6RSgO81hrhLtIg.',
                    docs: 'null',
                    price: null,
                    price2: null,
                    price3: null,
                    is_no_price: true,
                    is_delivery_required: false,
                    title: this.srv_title,
                    url: this.srv_title,
                    seo_title: this.srv_title,
                    alt: '560',
                    keywords: '255',
                    description: '/uploads/VZ2eLLwkxHBKKQZ1dEJwAEVGN304RKF4qelZyrYo.png',
                    text: 'null',
                    subtext: 'null'
                }).then( res => {
                    this.$store.dispatch('service/NEW_service_list_GET', {cat: 2, num: this.num, page: this.page})
                    this.srv_title = ''
                    this.overlay_add = false
                })
            },

            async act(id, state){
                let temp = {
                    field: "is_active",
                    value: state
                }
                await this.$store.dispatch('service/serv_PUT_field', {id: id, item: temp}).then(res => {
                    this.get_unit(id, state)
                })
            },

          async get_unit(id, state){
            await this.$store.dispatch('service/unite_GET', id).then(res => {
              console.log(this.unit)
              if( !this.unit.length && state){
                let un ={
                  name: "test",
                  location_id: 1,
                  service_id: id,
                  notes: "",
                  number: '10000'
                }
                this.$store.dispatch('service/unite_POST', un)
                console.log('+ unit')

              }
            })
          },

            async on_change(id){
                this.page = id
                await this.start()

            },


            change_num(num){
                this.num = num
                this.page = 1
                this.start()
            },

            del(item){
              this.qq = item
              this.$store.dispatch('service/unite_GET', this.qq.id)
              this.del_ask = true
                //this.$store.dispatch("service/service_DELETE", id)
                //this.start()
            },

          async del_me(){
              await this.del_unit().then( res => {
                this.$store.dispatch("service/service_DELETE", this.qq.id)
                this.del_ask = false
                this.start()
              })
          },

          async del_unit(){
            for (let i in this.unit.data){
              await this.$store.dispatch('service/DelUnit', this.unit.data[i].id)
            }
          },

            open_srv(item){
                this.srv = item
              this.$router.push('/service/' + item.id)
                //this.dialog = true
            },

          save_me(){
              this.$refs.servqq.save()
          }

        },
        created() {
            this.start()
        }
    }
</script>

<style scoped>
</style>
