<template>
    <div>
        <v-img @click="$refs.file.click()"
               :contain="contain"
               :src="'https://api.timetr.org/'+ img"
               :position="left < 0 ? 'center' : left + '%'"
               :height="max_height ? '' : height" :max-height="max_height ? height : ''" :max-width="width">
            <div class="hov d-flex fill-height justify-center align-center">
                    <v-icon color="white" x-large>mdi-refresh</v-icon>
            </div>
        </v-img>
        <input type="file"
               id="file"
               ref="file"
               accept=".jpg, .jpeg, .png, .webp, .gif, .svg"
               style="display: none"
               v-on:change="handleFileUpload()"/>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    export default {
        name: "img_load",
        data: () => ({
            file: '',
        }),
        props:{
            set_img: {},
            img: '',
            i: 0,
            contain: Boolean,
          max_height: Boolean,
            height:{
                type: [String, Number],
                default: 150
            },
            width:{
                type: [String, Number],
                default: 250
            },
          left: {
              type: [String, Number],
            default: -1
          },
          case: {
            type: String,
            default: "cover"
          }
        },
        computed:{
            apiDomain(){ return this.$store.getters['apiDomain'] },
            file_store(){ return this.$store.getters['file_store'] },
        },
        watch: {
            file() {
                this.submitFile()
            },
        },
        methods:{
            ...mapActions[('uploadFile')],

            handleFileUpload() {
                this.file = this.$refs.file.files[0]
            },
            async submitFile() {
                let formData = new FormData()
                formData.append('file', this.file)
                await this.$store.dispatch('uploadFile', { data: formData, use_case: this.case })
                console.log(this.img)
                console.log(this.file_store)
                this.set_img(this.img , this.file_store.url, this.i)
            },
        }
    }
</script>

<style scoped lang="scss">

    .v-image{
        border: 1px solid rgba(#607D8B, .2);
        background-color: rgba(#607D8B, .09);
        &:hover{
            cursor: pointer;
        }
    }

    .hov{
        opacity: 0;
        background: radial-gradient(#607D8B 3%, transparent);
        color: white;
        transition: 1s;
    }

    .v-image:hover .hov{
        opacity: 1;
    }
</style>
