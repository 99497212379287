<template>
  <div class="login-page d-flex flex-column justify-center align-center pa-3">
      <v-img src="./img/logo.png" max-width="200" max-height="120" contain/>
    <v-form>
      <v-card elevation="5" class="mt-10" width="450">
        <div class="pa-5">
          <v-card-title>
            {{enter}}
          </v-card-title>
          <v-text-field v-model="userInfo.username"
                        hide-details
                        :label="email"
                        prepend-inner-icon="mdi-email"
                        class="mt-5"/>
          <v-text-field v-model="userInfo.password"
                        hide-details
                        :label="password"
                        prepend-inner-icon="mdi-lock"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        :type="show ? 'text' : 'password'"
                        v-on:keyup.enter="submit(userInfo)"
                        class="mt-3 mb-10"/>
        </div>
        <v-divider/>
        <div class="pa-5">
          <v-row justify="end" class="mx-0">

            <v-btn @click="submit(userInfo)"
                   large color="primary" class="px-6">
              {{log_in}}
              <v-icon class="ml-4">mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-row>
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
    export default {
      name: "Login",
      props: ['submit'],
      data: () =>({
        img: './img/logo.png',
        enter: "Вход",
        email: "Почта",
        password: "Пароль",
        memory: "Запомнить меня",
        forgot: "Забыли пароль?",
        log_in: "Войти",

        show: false,
        checkbox: false,
        valid: false,
        showPassword: false,

        userInfo: {
          username: '',
          password: '',
          client_id: '1',
          client_secret: 'kAUsFYysaEEi82JrFgzgYcU8SJzx8ubRkf7NJj8l',
          grant_type: 'password',
        },
      })
    }
</script>

<style scoped lang="scss">
  .login-page{
    height: 90vh;
  }
</style>
